import React, {useEffect, useState} from "react";
import {useFormik} from "formik";
import AvatarUpload from "../../common/avatar/AvatarUpload";
import * as actions from "./ProfileActions";
import _ from "lodash";
import {useNavigate} from "react-router-dom";
import * as Yup from "yup";
import Loader from "../../common/loader/Loader";
import FormField from "../../common/formField/FormField";
import {useContextProvider} from "../../context/ContextProvider/ContextProvider";
import CardBody from "../../containers/card/CardBody";
import Card from "../../containers/card/Card";
import {SettingsCardHeader} from "./SettingsCardHeader";
import {requiredMessage,formikDefaults} from "../../common/utils/utils";

const validationSchema = Yup.object({
    username: Yup.string().required(requiredMessage),
    password: Yup.string()
        .min(6, 'Must be at least 6 characters')
        .when('change_password', {
            is: true,
            then: (schema) => schema.required(requiredMessage),
            otherwise: (schema) => schema.notRequired(),
        }),
    confirm_password: Yup.string()
        .oneOf([Yup.ref('password'), null], 'Passwords must match')
        .when('change_password', {
            is: true,
            then: (schema) => schema.required(requiredMessage),
            otherwise: (schema) => schema.notRequired(),
        }),
});
const Profile = () => {
    const {profile, reloadProfile} = useContextProvider();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [avatarForm, setAvatarForm] = useState(null);

    const saveProfile = (values) => {
        if (!values.change_password && values.id) {
            values = _.omit(values, ['password']);
        }

        values = _.omit(values, ['change_password', 'confirm_password', 'picture_path']);

        if (avatarForm) {
            actions.uploadAvatar(avatarForm, () => {
                setAvatarForm(null)
            });
        }
        actions.saveProfile(values, () => {
            if (avatarForm) {
                actions.uploadAvatar(avatarForm, () => {
                    setAvatarForm(null)
                    reloadProfile();
                    navigate("/");
                });
            } else {
                reloadProfile();
                navigate("/");
            }

        });
    }

    const formik = useFormik({
        ...formikDefaults,
        onSubmit: saveProfile,
        validationSchema
    });

    useEffect(() => {
        reloadProfile(
            () => {
                setLoading(false);
            }
        );
    }, []);

    useEffect(() => {
        if (profile) {
            formik.setValues(profile);
        }
    }, [profile]);

    return (
        <Card className="settings profile">
            <SettingsCardHeader tab="profile" onSave={formik.submitForm}/>
            <CardBody className="form">
                {loading ? <Loader/> : <>
                    <div className='buttons'>
                    </div>
                    {formik.values && <form autoComplete="off">
                        <FormField
                            id="username-input"
                            label="Username"
                            name="username"
                            value={formik.values.username}
                            handleChange={formik.handleChange}
                            handleBlur={formik.handleBlur}
                            touched={formik.touched.username}
                            errors={formik.errors.username}
                            isRequired={true}
                            disabled={formik.values.ldap_user}
                        />
                        <FormField
                            id="name-input"
                            label="Name"
                            name="name"
                            value={formik.values.name}
                            handleChange={formik.handleChange}
                            handleBlur={formik.handleBlur}
                            touched={formik.touched.name}
                            errors={formik.errors.name}
                            disabled={formik.values.ldap_user}
                        />
                        <FormField
                            id="surname-input"
                            label="Surname"
                            name="surname"
                            value={formik.values.surname}
                            handleChange={formik.handleChange}
                            handleBlur={formik.handleBlur}
                            touched={formik.touched.surname}
                            errors={formik.errors.surname}
                            disabled={formik.values.ldap_user}
                        />
                        <FormField
                            id='avatar-input'
                            label='Avatar'
                            renderInput={false}
                        >
                            <AvatarUpload image={profile.picture_path} setAvatarForm={setAvatarForm} username={formik.values.username} disabled={formik.values.ldap_user}/>
                        </FormField>


                        {!formik.values.ldap_user && (
                            <>
                                <FormField
                                    id="change-password-input"
                                    label="Change password"
                                    type="checkbox"
                                    name="change_password"
                                    value={formik.values.change_password}
                                    handleChange={formik.handleChange}
                                    handleBlur={formik.handleBlur}
                                    touched={formik.touched.change_password}
                                    errors={formik.errors.change_password}
                                />
                                {formik.values.change_password &&
                                    <>
                                        <FormField
                                            id="password-input"
                                            label="Password"
                                            type="password"
                                            name="password"
                                            value={formik.values.password}
                                            handleChange={formik.handleChange}
                                            handleBlur={formik.handleBlur}
                                            touched={formik.touched.password}
                                            errors={formik.errors.password}
                                            isRequired={formik.values.change_password}
                                        />
                                        <FormField
                                            id="confirm_password-input"
                                            label="Confirm password"
                                            type="password"
                                            name="confirm_password"
                                            value={formik.values.confirm_password}
                                            handleChange={formik.handleChange}
                                            handleBlur={formik.handleBlur}
                                            touched={formik.touched.confirm_password}
                                            errors={formik.errors.confirm_password}
                                            isRequired={formik.values.change_password}
                                        />
                                    </>}
                            </>
                        )}
                    </form>}</>}
            </CardBody>
        </Card>
    )

}

export default Profile;