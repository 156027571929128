import React, {useEffect, useMemo, useState} from 'react';
import {Tooltip} from 'react-tooltip';
import "./BuildsJobs.scss"
import * as actions from "./BuildsJobsActions"
import {useNavigate} from "react-router-dom";
import Table from "../../common/tables/Table";
import CardHeader from "../../containers/card/CardHeader";
import Card from "../../containers/card/Card";
import {buildIcon} from "../../common/icons/Icon";
import TableActions from "../../common/buttons/TableActions";
import CardBody from "../../containers/card/CardBody";

const BuildsJobs = () => {
    const [jobs, setJobs] = useState([]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    const refreshList = () => {
        actions.loadJobs({}, (data) => {
            setJobs(data);
            setLoading(false);
        });
    }

    useEffect(() => {
        refreshList();
    }, []);

    const columns = useMemo(() => [
            {
                Header: 'Job Name',
                accessor: 'name',
                Cell: ({row}) => (
                    <div className='clickable' onClick={() => navigate(`/builds/${row.original.slug}`)}>
                        <p>{row.original.name}</p>
                    </div>)
            },
            {
                Header: 'Options',
                Cell: ({row}) => (
                    <TableActions row={row.original} name='Build' onShow={(job) => navigate(`/builds/${job.slug}`)}/>

                )
            }
        ]
        , []);


    return (
        <Card className="builds-slugs">
            <CardHeader pathnames={[[null, "Builds"]]} icon={buildIcon}/>
            <CardBody>
                <Table columns={columns} data={jobs} loading={loading}/>
            </CardBody>
            <Tooltip id="status-tooltip" className='builds-tooltip'/>
        </Card>
    );
}

export default BuildsJobs;
