import FormField from "../formField/FormField";
import React from "react";
import Icon, {searchIcon} from "../icons/Icon";

import "./SearchInput.scss"

const SearchInput = ({handleChange}) => {
    return (
        <div className="search-input">
            <Icon icon={searchIcon} />
            <FormField type='text' handleChange={handleChange} placeholder={"Search"} />
        </div>
    )
}

export default SearchInput