import axios from "axios";

export const checkLdap = (callback) => {
    const config = {
        url: `/ldaps/check_ldap`
    }

    axios.request(config).then(
        response => {
            callback(response.data);
        }
    )
}

export const loadUser = (id, callback) => {
    const config = {
        url: `/users/${id}`
    }

    axios.request(config).then(
        response => {
            callback(response.data);
        }
    )
}

export const loadClient = (id, callback) => {
    const config = {
        url: `/clients/${id}`
    }

    axios.request(config).then(
        response => {
            callback(response.data);
        }
    )
}

export const saveUser = (data, callback) => {
    const config = {
        url: data.id ? `/users/${data.id}` : '/users',
        method: data.id ? 'PUT' : 'POST',
        data: {
            user: data
        }
    }

    axios.request(config).then(
        response => {
            callback(response.data);
        }
    )
}