import axios from "axios";

export const checkLdap = (callback) => {
    const config = {
        url: `/ldaps/check_ldap`
    }

    axios.request(config).then(
        response => {
            callback(response.data);
        }
    )
}

export const loadGroups = (params, callback) => {
    const config = {
        url: '/groups',
        params: params
    };


    axios.request(config).then(
        response => {
            callback(response.data);
        }
    )
}

export const deleteGroup = (id, callback) => {
    const config = {
        url: `/groups/${id}`,
        method: 'DELETE'
    }

    axios.request(config).then(
        response => {
            callback();
        }
    )
}