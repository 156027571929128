import React from "react";
import {useFormik} from "formik";
import * as Yup from "yup";
import Modal from "../../../common/modals/Modal";
import FormField from "../../../common/formField/FormField";
import Button from "../../../common/buttons/Button";
import "./IntegrationModal.scss"
import CancelSaveButtons from "../../../common/buttons/CancelSaveButtons";
import ModalHeader from "../../../common/modals/ModalHeader";
import ModalBody from "../../../common/modals/ModalBody";
import ModalFooter from "../../../common/modals/ModalFooter";
import {formikDefaults, requiredMessage} from "../../../common/utils/utils";

const validationSchema = Yup.object({
    name: Yup.string().required(requiredMessage),
    url: Yup.string().required(requiredMessage),
    jenkins_username: Yup.string().required(requiredMessage),
    jenkins_token: Yup.string().required(requiredMessage)
});

const IntegrationModal = ({onClose, onSave, onDelete, integration}) => {
    const formik = useFormik({
        ...formikDefaults,
        initialValues: {
            ...integration,
            integration_type: "jenkins"
        },
        validationSchema,
        validateOnChange: false,
        validateOnBlur: false,
        onSubmit: (values) => {
            onSave(values);
            onClose();
        },
    });

    const handleDelete = () => {
        if (integration && integration.id) {
            onDelete(integration.id);
            onClose();
        }
    }

    return (
        <Modal
            onClose={onClose}
            size='md'
            height='fit-content'
            className="integration-modal"
        >
            <ModalHeader title="Integrations" value={integration.id ? integration.name : "New"}/>
            <ModalBody>
                <form>

                    <FormField
                        id='platform'
                        label='Platform'
                        renderInput={false}
                    >
                        <div
                            role="group"
                            id="platform-radio-group"
                            className="radio-group"
                        >
                            <label>
                                <input
                                    type="radio"
                                    name="platform"
                                    value="jenkins"
                                    checked={formik.values.integration_type === "jenkins"}
                                    onChange={formik.handleChange}
                                />
                                Jenkins
                            </label>
                        </div>
                    </FormField>
                    <FormField
                        id="name-input"
                        label="Name"
                        name="name"
                        value={formik.values.name}
                        handleChange={formik.handleChange}
                        handleBlur={formik.handleBlur}
                        touched={formik.touched.name}
                        errors={formik.errors.name}
                        isRequired
                    />
                    <FormField
                        id="jenkins-job-url-input"
                        label="Jenkins url"
                        name="url"
                        value={formik.values.url}
                        handleChange={formik.handleChange}
                        handleBlur={formik.handleBlur}
                        touched={formik.touched.url}
                        errors={formik.errors.url}
                        isRequired
                    />
                    <FormField
                        id="jenkins-username"
                        label="Jenkins username"
                        name="jenkins_username"
                        value={formik.values.jenkins_username}
                        handleChange={formik.handleChange}
                        handleBlur={formik.handleBlur}
                        touched={formik.touched.jenkins_username}
                        errors={formik.errors.jenkins_username}
                        isRequired
                    />
                    <FormField
                        id="jenkins-token"
                        label="Jenkins token"
                        name="jenkins_token"
                        value={formik.values.jenkins_token}
                        handleChange={formik.handleChange}
                        handleBlur={formik.handleBlur}
                        touched={formik.touched.jenkins_token}
                        errors={formik.errors.jenkins_token}
                        isRequired
                    />
                </form>
            </ModalBody>

            <ModalFooter>
                <>
                    {integration.id &&
                        <Button className="delete-button" color='danger' onClick={handleDelete}>Delete</Button>
                    }
                    <CancelSaveButtons onCancel={onClose} onSave={formik.submitForm}/>
                </>
            </ModalFooter>
        </Modal>
    );
}

export default IntegrationModal;