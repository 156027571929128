import {statusColors} from "../utils/constants";
import {formatStatus} from "../utils/utils";
import './StatusDot.scss'

const StatusDot = ({ status }) => {
    return (
        <div
            className='status-dot'
            style={{
                backgroundColor: statusColors[status] || '#d6d6d6',
            }}
            data-tooltip-id="status-tooltip"
            data-tooltip-content={formatStatus(status)}
            data-tooltaip-place='left'
        />
    );
};

export default StatusDot;
