import {userInitials} from "../utils/utils";
import "./Initials.scss"

const Initials = ({profile}) => {
    return (
        <div className="initials">
            {userInitials(profile.name, profile.surname)}
        </div>
    )
}

export default Initials