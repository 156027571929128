import PieChart from "../chart/PieChart";
import StackedLineChart from "../chart/StackedLineChart";
import LineChart from "../chart/LineChart";
import './CardReports.scss'
import BuildStatus from "./BuildStatus";
import {groupStatuses} from "../../common/utils/utils";

const CardReports = ({lastBuildStatus, lastBuildDate, data}) => {

    const pieChartData = groupStatuses(data);
    return (
        <div className="card-reports">
            <PieChart data={pieChartData} hasTotal/>
            <StackedLineChart data={data}/>
            <LineChart data={data}/>

            <div className="section status">
                <BuildStatus status={lastBuildStatus} label="Last Run" time={lastBuildDate}/>
            </div>
        </div>
    )
}

export default CardReports