import {statusColors} from "../utils/constants";
import "./StatusOption.scss"

const StatusOption = ({label}) => {
    return (
        <div className="status-option">
            {label ? (
                <>
                    <div className="dot" style={{backgroundColor: statusColors[label.toUpperCase().replace(/ /g, "_")]}}/>
                    {label}
                </>
            ) : ""}
        </div>
    )
}

export default StatusOption