import axios from "axios";

export const saveProfile = (data, callback) => {
    const config = {
        url: `/profile/user`,
        method: 'PUT',
        data: {
            user: data
        }
    }

    axios.request(config).then(
        response => {
            callback(response.data);
        }
    )
}

export const uploadAvatar = (formData, callback) => {
    const config = {
        url: `/profile/picture`,
        method: 'POST',
        data: formData,
        headers: {
            "Content-Type": "multipart/form-data", processData: "false"
        },
    };

    axios.request(config).then(
        (response) => {
            callback(response.data);
        }
    );
};