import React from "react";
import Button from "../buttons/Button";
import "./DeleteModal.scss";
import Modal from "./Modal";
import ModalHeader from "./ModalHeader";


const DeleteModal = ({resource, name, onClose, onDelete}) => {
    return (
        <Modal
            onClose={onClose}
            height='fit-content'
            className='delete'
        >
            <ModalHeader value={resource} title={name}/>
            <div className='body'>
                Are you sure, you want to delete <b>{resource}</b>?
            </div>
            <div className='footer'>
                <Button
                    color="secondary"
                    onClick={onClose}>
                    Cancel
                </Button>
                <Button
                    onClick={onDelete}
                    color="primary">
                    Delete
                </Button>
            </div>
        </Modal>
    )
        ;
}

export default DeleteModal;