import React from 'react';
import {useParams} from "react-router-dom";
import Builds from "./Builds";

const BuildsRouter = () => {
    const {jobSlug, tag} = useParams();

    return <Builds jobSlug={jobSlug} tag={tag}/>
}

export default BuildsRouter;
