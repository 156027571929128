import React from 'react';
import classnames from 'classnames';
import Select from 'react-select';
import {selectStyles} from "../styles/selectStyles";
import './FormField.scss';

const FormField = ({
                       label,
                       type = 'text',
                       name,
                       value,
                       checked,
                       handleChange,
                       handleBlur,
                       touched,
                       errors,
                       options = null,
                       isMulti = false,
                       id,
                       autoComplete = 'off',
                       placeholder = '',
                       className = '',
                       children,
                       renderInput = true,
                       isRequired = false,
                       disabled
                   }) => {
    const isError = touched && errors;

    const renderInputField = () => {
        switch (type) {
            case 'select':
                return (
                    <Select
                        isMulti={isMulti}
                        name={name}
                        value={value}
                        onChange={handleChange}
                        options={options}
                        onBlur={handleBlur}
                        className={classnames('form-select', className, {disabled: disabled})}
                        styles={selectStyles}
                        isDisabled={disabled}
                    />
                );
            case 'checkbox':
                return (
                    <input
                        id={id}
                        type={type}
                        className={classnames('form-input form-checkbox', className, {disabled: disabled})}
                        name={name}
                        value={value}
                        checked={checked}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        disabled={disabled}
                    />
                );
            default:
                return (
                    <input
                        id={id}
                        type={type}
                        className={classnames('form-input', className, {disabled: disabled})}
                        name={name}
                        value={value}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        autoComplete={autoComplete}
                        placeholder={placeholder}
                        disabled={disabled}
                    />
                );
        }
    };

    return (
        <div className="form-field">
            {label && (
                <label htmlFor={id} className={classnames("form-label", {
                    "checkbox": type === 'checkbox',
                    "required": isRequired
                })}>
                    {label}
                </label>
            )}
            {renderInput && <div className="input-content">
                {renderInputField()}
                {errors && <div className="error-message">{errors}</div>}
            </div>}
            {children && <div className={classnames("input-content", className)}>{children} </div>}
        </div>
    );
};

export default FormField;
