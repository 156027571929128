import axios from "axios";

export const loadJobs = (params, callback) => {
    const config = {
        url: '/jobs',
        params: params
    };

    axios.request(config).then(
        response => {
            callback(response.data);
        }
    )
}