import Table from "../../common/tables/Table";
import React, {useEffect, useMemo, useState} from "react";

import * as actions from "./GroupsActions"
import TableActions from "../../common/buttons/TableActions";
import {useNavigate} from "react-router-dom";
import CardBody from "../../containers/card/CardBody";
import Card from "../../containers/card/Card";
import * as PropTypes from "prop-types";
import {SettingsCardHeader} from "./SettingsCardHeader";

SettingsCardHeader.propTypes = {
    role: PropTypes.string,
    onClick: PropTypes.func
};
const Groups = () => {
    const [loading, setLoading] = useState(true);
    const [groups, setGroups] = useState([]);
    const role = localStorage.getItem('role');
    const navigate = useNavigate();
    const [ldapConnected, setLdapConnected] = useState(false);

    const refreshList = () => {
        actions.checkLdap(setLdapConnected)

        actions.loadGroups({}, (data) => {
            setGroups(data);
            setLoading(false);
        });
    };


    const deleteAction = (group) => {
        actions.deleteGroup(group.id, () => {
            refreshList();
        });
    };

    const editAction = (group) => {
        navigate(`/settings/group/${group.id}`);
    };

    useEffect(() => {
        refreshList();
    }, []);

    const columns = useMemo(() => [
        {
            Header: 'Name',
            accessor: 'name',
            Cell: ({row}) => (
                <div className='row-name'>
                    <p>{row.original.name}</p>
                </div>
            )
        },
        {
            Header: 'Options',
            Cell: ({row}) => (
                role === 'admin' &&
                <TableActions row={row.original} rowName={row.original.name} name='Groups' onEdit={editAction} onDelete={deleteAction}
                              disabledOnDelete={row.original.ldap_group}/>
            )
        }
    ], []);

    return <Card className="settings groups">
        <SettingsCardHeader tab="groups" onAdd={!loading && !ldapConnected ? () => navigate("/settings/group") : null}/>
        <CardBody>
            <Table columns={columns} data={groups} loading={loading}/>
        </CardBody>
    </Card>
}

export default Groups