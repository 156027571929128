import Modal from "../../../common/modals/Modal";
import CancelSaveButtons from "../../../common/buttons/CancelSaveButtons";
import ModalHeader from "../../../common/modals/ModalHeader";
import ModalBody from "../../../common/modals/ModalBody";
import ModalFooter from "../../../common/modals/ModalFooter";
import React from "react";

const LdapWarningModal = ({onClose, onContinue}) => {
    return (
        <Modal isOpen={true} height="fit-content">
            <ModalHeader title="Connecting" value="LDAP"/>
            <ModalBody>
                WARNING: If you connect LDAP, all your users (except admins) will be deleted, do you want to continue?
            </ModalBody>
            <ModalFooter>
                <CancelSaveButtons onCancel={onClose} onSave={onContinue} />
            </ModalFooter>
        </Modal>
    )
}

export default LdapWarningModal