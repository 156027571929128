import React from "react";
import classnames from "classnames";
import Icon from "../icons/Icon";
import {useNavigate} from "react-router-dom";
import './NavbarItem.scss';

const NavbarItem = ({icon, text, path, isSelected, navbarItemRedirect = true}) => {
    const navigate = useNavigate();
    const handleNavbarItemClicked = (path) => {
        navigate(path);
    }

    const selected = isSelected(path);


    const itemClasses = classnames('navbar-item', {
        selected: selected
    });

    return (
        <div className={itemClasses} onClick={() => navbarItemRedirect && handleNavbarItemClicked(path)}>
            <Icon icon={icon}/>
            <div className='text'>{text}</div>
        </div>
    )
}

export default NavbarItem;