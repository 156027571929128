import StatusDot from "../status/StatusDot";
import Icon, {breadcrumbArrowIcon} from "../icons/Icon";

import {statusColors} from "../utils/constants";
import Button from "../buttons/Button";
import LazyImage from "../lazyImage/LazyImage";
import Dock from "./Dock";
import "./HistoryViewDock.scss"
import ImageDiffPreview from "../imageDiffPreview/ImageDiffPreview";
import {useState} from "react";

const HistoryViewDock = ({onCancel, report}) => {
    const [selectedImage, setSelectedImage] = useState(null)
    return (
        <>
            <Dock className="history-view-modal" onCancel={onCancel}>
                <div className="header">
                    <div className="feature-name">
                        {report.feature.name}
                    </div>
                    <Icon icon={breadcrumbArrowIcon}/>
                    <div className="scenario-name">
                        <StatusDot status={report.status}/>
                        {report.scenario}
                    </div>
                </div>
                <div className="body">
                    {report.steps?.map(step => (
                        <div className="step">
                            <div className="description">
                                <div className="status" style={{backgroundColor: statusColors[step.status]}}/>
                                <span className="keyword">{step.keyword}</span>
                                <span className="text">{step.text}</span>
                            </div>
                            {step.dataTable && (
                                <table className="data-table">
                                    {step.dataTable?.map(row => (
                                        <tr>
                                            {row.map(cell =>
                                                <td>{cell}</td>
                                            )}
                                        </tr>
                                    ))}
                                </table>
                            )}
                            {step.status === "FAILED" && step.error && (
                                <span className="error">{step.error.split("\n").map(line => (
                                    <>
                                        <div className="line">{line}</div>
                                    </>
                                ))}</span>
                            )}
                            {step.images &&
                                <div className="images">
                                    <Button size="image" onClick={() => setSelectedImage(step)}>
                                        <LazyImage imageLabel="Actual" src={step.images.actual_mini}/>
                                    </Button>
                                    <Button size="image" onClick={() => setSelectedImage(step)}>
                                        <LazyImage imageLabel="Different" src={step.images.diff_mini}/>
                                    </Button>
                                    <Button size="image" onClick={() => setSelectedImage(step)}>
                                        <LazyImage imageLabel="Expected" src={step.images.expected_mini}/>
                                    </Button>
                                </div>
                            }
                        </div>
                    ))}
                </div>
            </Dock>
            {selectedImage && <ImageDiffPreview
                expected={selectedImage.images.expected}
                diff={selectedImage.images.diff}
                actual={selectedImage.images.actual}
                onClose={() => setSelectedImage(null)}
            />}
        </>
    )
}

export default HistoryViewDock