import React, {useState} from "react";
import DeleteModal from "../modals/DeleteModal";
import Icon, { deleteIcon, editIcon, searchIcon} from "../icons/Icon";
import Button from "./Button";


const TableActions = ({row, rowName, name, onEdit, onDelete, onShow, disabledOnDelete, disableOnEdit, customButtons}) => {
    const [toDelete, setToDelete] = useState(null)

    return (
        <div className='actions'>
            {toDelete && <DeleteModal
                name={name}
                resource={rowName}
                onDelete={() => {
                    onDelete(row)
                    setToDelete(null)
                }}
                onClose={() => setToDelete(null)}/>
            }
            {onEdit &&
                <Button size='icon'
                        disabled={disableOnEdit}
                        onClick={() => onEdit(row)
                        }>
                    <Icon icon={editIcon}/>
                </Button>}
            {onShow &&
                <Button size='icon'
                        onClick={() => onShow(row)
                        }>
                    <Icon icon={searchIcon}/>
                </Button>}
            {onDelete &&
                <Button
                    color="icon"
                    onClick={() => setToDelete(row)}
                    disabled={disabledOnDelete}
                >
                    <Icon icon={deleteIcon}/>
                </Button>}
            {customButtons}
        </div>
    );
}

export default TableActions;