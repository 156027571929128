import CardHeader from "../../containers/card/CardHeader";
import {settingsIcon} from "../../common/icons/Icon";
import Filter from "../../common/filter/Filter";
import {settingsTabs} from "../../common/utils/utils";
import React from "react";
import {useNavigate} from "react-router-dom";

export function SettingsCardHeader({tab, onSave, onAdd}) {
    const navigate = useNavigate();
    const role = localStorage.getItem('role');

    return <CardHeader icon={settingsIcon} pathnames={[[null, "Settings"]]}
                       onSave={onSave}
                       onAdd={onAdd}
                       onCancel={() => navigate(`/settings/profile`)}
                       tabs={<Filter items={settingsTabs(role)} initialTab={tab} page="Settings"
                                     onClick={itemName => navigate(`/settings/${itemName.toLocaleLowerCase()}`)}
                       />}
    />;
}