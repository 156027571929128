import React from 'react';
import classnames from "classnames";
import "./CardBody.scss"

const CardBody = ({id, className, children}) => {
    return (
        <div
            className={classnames("card-body", className)}
            id={id}
        >
            <div className='content'>
                {children}
            </div>
        </div>
    )
}

export default CardBody;