import "./Dock.scss"
import classnames from "classnames"
const Dock = ({children, className, onCancel}) => {
    return (
        <div className={classnames("dock", className)}>
            <div className="backdrop" onClick={onCancel} />
            <div className="content">
                {children}
            </div>
        </div>
    )
}

export default Dock