import React, {forwardRef} from "react";
import Modal from "../../modals/Modal";
import {ReactCrop} from "react-image-crop";
import CancelSaveButtons from "../../buttons/CancelSaveButtons";
import ModalBody from "../../modals/ModalBody";
import ModalHeader from "../../modals/ModalHeader";
import ModalFooter from "../../modals/ModalFooter";

const CropModal = forwardRef(({
                                  imgSrc,
                                  crop,
                                  onChange,
                                  onClose,
                                  onComplete,
                                  onLoad,
                                  onCancel,
                                  onSave
                              }, ref) => {
    return (
        <Modal
            onClose={onClose}
            className='crop'
        >
            <ModalHeader title="Profile" value="Crop your avatar"/>
            <ModalBody>
                {!!imgSrc && (
                        <ReactCrop
                            crop={crop}
                            onChange={onChange}
                            onComplete={onComplete}
                            aspect={1}
                            minHeight={300}
                            minWidth={300}
                            circularCrop
                            keepSelection
                        >
                            <img
                                ref={ref}
                                alt="Crop me"
                                src={imgSrc.url}
                                onLoad={onLoad}
                                className='image'
                            />
                        </ReactCrop>
                )}
            </ModalBody>
            <ModalFooter>
                <CancelSaveButtons onCancel={onCancel} onSave={onSave}/>
            </ModalFooter>
        </Modal>
    )
});

export default CropModal;