import {TransformComponent, TransformWrapper} from "react-zoom-pan-pinch";
import LazyImage from "../lazyImage/LazyImage";

const ImageWithTransform = ({src, alt, tranformRef, onZoomPan, getHeightStyle}) => {
    return (
        <TransformWrapper
            ref={tranformRef}
            onZoom={onZoomPan}
            onPanning={onZoomPan}
            disablePadding={true}
            panning={{velocityDisabled: true}}
            doubleClick={{disabled: true}}
            wheel={{smoothStep: 0.003}}
        >
            <TransformComponent wrapperStyle={getHeightStyle ? getHeightStyle() : {}}>
                <LazyImage src={src} alt={alt || ""}/>
            </TransformComponent>
        </TransformWrapper>
    )
}

export default ImageWithTransform