import React from "react";
import FormField from "../../../common/formField/FormField";
import Button from "../../../common/buttons/Button";
import Modal from "../../../common/modals/Modal";
import {useFormik} from "formik";
import * as Yup from "yup";
import ModalHeader from "../../../common/modals/ModalHeader";
import ModalBody from "../../../common/modals/ModalBody";
import ModalFooter from "../../../common/modals/ModalFooter";
import "./JenkinsJobModal.scss";
import CancelSaveButtons from "../../../common/buttons/CancelSaveButtons";
import {requiredMessage,formikDefaults} from "../../../common/utils/utils";

const validationSchema = Yup.object({
    jenkins_name: Yup.string().required(requiredMessage),
    jenkins_token: Yup.string().required(requiredMessage),
});

const JenkinsJobModal = ({onClose, onSave, isOpen, jenkinsJob, jenkinsName}) => {

    const handleDisconnect = () => {
        onSave({
            integration_id: null,
            jenkins_name: null,
            jenkins_token: null,
        })
        onClose();
    }
    const formik = useFormik({
        ...formikDefaults,
        initialValues: {...jenkinsJob},
        validationSchema,
        onSubmit: (values) => {
            onSave(values);
            onClose();
        },
    });
    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            size="md"
            className="jenkins-job"
            height='fit-content'>
            <ModalHeader title="Integrations" value={`Jenkins: ${jenkinsName}`}/>
            <ModalBody>
                <form onSubmit={formik.handleSubmit}>
                    <FormField
                        id="name-input"
                        label="Jenkins Job Name"
                        name="jenkins_name"
                        value={formik.values.jenkins_name}
                        handleChange={formik.handleChange}
                        handleBlur={formik.handleBlur}
                        touched={formik.touched.jenkins_name}
                        errors={formik.errors.jenkins_name}
                        isRequired
                    />
                    <FormField
                        id="jenkins-job-token"
                        label="Jenkins Job Token"
                        name="jenkins_token"
                        value={formik.values.jenkins_token}
                        handleChange={formik.handleChange}
                        handleBlur={formik.handleBlur}
                        touched={formik.touched.jenkins_token}
                        errors={formik.errors.jenkins_token}
                        isRequired
                    />
                </form>
            </ModalBody>
            <ModalFooter>
                {jenkinsJob.jenkins_name && jenkinsJob.jenkins_token &&
                    <Button className="disconnect-button" color='danger' onClick={handleDisconnect}>Disconnect</Button>
                }
                <CancelSaveButtons onCancel={onClose} onSave={formik.submitForm}/>
            </ModalFooter>
        </Modal>
    );
}

export default JenkinsJobModal;