import axios from "axios";

export const checkLdap = (callback) => {
    const config = {
        url: `/ldaps/check_ldap`
    }

    axios.request(config).then(
        response => {
            callback(response.data);
        }
    )
}

export const loadUsers = (params, callback) => {
    const config = {
        url: '/users',
        params: params
    };


    axios.request(config).then(
        response => {
            callback(response.data);
        }
    )
}

export const deleteUser = (id, callback) => {
    const config = {
        url: `/users/${id}`,
        method: 'DELETE'
    }

    axios.request(config).then(
        response => {
            callback();
        }
    )
}

export const saveUser = (data, callback) => {
    const config = {
        url: data.id ? `/users/${data.id}` : '/users',
        method: data.id ? 'PUT' : 'POST',
        data: {
            user: data
        }
    }

    axios.request(config).then(
        response => {
            callback && callback(response.data);
        }
    )
}

export const loadGroups = (params, callback) => {
    const config = {
        url: '/groups',
        params: params
    };


    axios.request(config).then(
        response => {
            callback(response.data);
        }
    )
}

export const saveGroup = (data, callback) => {
    const config = {
        url: data.id ? `/groups/${data.id}` : '/groups',
        method: data.id ? 'PUT' : 'POST',
        data: {
            group: data
        }
    }

    axios.request(config).then(
        response => {
            callback && callback(response.data);
        }
    )
}