import React, {useCallback, useEffect, useRef, useState} from 'react';
import 'react-image-crop/dist/ReactCrop.css';
import './AvatarUpload.scss';
import {makeAspectCrop} from "react-image-crop";
import {useDropzone} from 'react-dropzone'
import classnames from "classnames";
import Avatar from "./Avatar";
import Button from "../buttons/Button";
import Icon, {deleteIcon} from "../icons/Icon";
import * as actions from "./AvatarUploadActions";
import DeleteModal from "../modals/DeleteModal";
import CropModal from "./crop/CropModal";
import {useContextProvider} from "../../context/ContextProvider/ContextProvider";
import {userInitials} from "../utils/utils";

const AvatarUpload = ({image, setAvatarForm, disabled, username}) => {
    const [imgSrc, setImgSrc] = useState(null);
    const imgRef = useRef(null);
    const [crop, setCrop] = useState();
    const [completedCrop, setCompletedCrop] = useState();
    const [avatarImg, setAvatarImg] = useState(null);
    const [deleteModal, setDeleteModal] = useState(false);
    const {profile, reloadProfile} = useContextProvider();


    const onDrop = useCallback(acceptedFiles => {
        if(disabled) return;
        setImgSrc({url: URL.createObjectURL(acceptedFiles[0]), fileName: acceptedFiles[0].path})
    }, [])

    const getCroppedCanvas = (image, crop) => {
        const canvas = document.createElement('canvas');
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext('2d');

        ctx.drawImage(image, crop.x * scaleX, crop.y * scaleY, crop.width * scaleX, crop.height * scaleY, 0, 0, crop.width, crop.height);

        return canvas;
    };
    const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop})

    const handleCancelCrop = () => {
        setImgSrc(null);
        setCompletedCrop(null);
        setCrop(null)
    }

    function deleteStatesHandle() {
        setImgSrc(null);
        setCompletedCrop(null);
        setCrop(null)
        setAvatarImg(null)
        setDeleteModal(false)
    }

    const handleDelete = () => {
        if (image) {
            actions.deleteAvatar(() => {
                deleteStatesHandle();
                reloadProfile()
            });
        } else {
            deleteStatesHandle()
        }
    }

    const handleSave = () => {
        const canvas = getCroppedCanvas(imgRef.current, completedCrop);
        canvas.toBlob((blob) => {
            const formData = new FormData();
            formData.set('file', blob, imgSrc.fileName);
            setAvatarForm(formData);
            handleCancelCrop();
            setAvatarImg(URL.createObjectURL(blob));
        }, 'image/jpeg', 1);
    };

    const onImageLoad = (e) => {
        const {width, height} = e.currentTarget;
        const crop = makeAspectCrop(
            {
                unit: "px",
                width: 300,
            },
            1,
            width,
            height
        );
        setCrop(crop);
    };

    useEffect(() => {
        if (image) {
            actions.downloadAvatar('normal', (data) => {
                setAvatarImg(URL.createObjectURL(data));
            });
        }
    }, [image]);


    return (<>
        <div className="avatar-upload">
            <div className='dropzone'>
                <div {...getRootProps()}
                     className={classnames('area', {'active': isDragActive && !disabled})}
                >
                    {avatarImg ? <Avatar image={avatarImg} size='big'/> : <input {...getInputProps()} disabled={disabled} />}
                    {!avatarImg && disabled && <div className='initials'>{userInitials(profile.name, profile.surname)}</div>}
                    {!avatarImg && !disabled && (isDragActive ? <p>Drop the files here ...</p> :
                        <p>Drag or click to select files</p>)}
                </div>
                {avatarImg && !disabled &&
                    <Button onClick={() => setDeleteModal(true)} size="icon">
                        <Icon icon={deleteIcon}/>
                    </Button>}
            </div>
        </div>
        {deleteModal && <DeleteModal
            onDelete={handleDelete}
            onClose={() => setDeleteModal(false)}
            name={username}
            resource='avatar'
        />}
        {imgSrc && <CropModal
            imgSrc={imgSrc}
            onClose={() => setImgSrc(null)}
            crop={crop}
            onChange={(_, percentCrop) => setCrop(percentCrop)}
            onComplete={(c) => setCompletedCrop(c)}
            ref={imgRef}
            onLoad={onImageLoad}
            onCancel={handleCancelCrop}
            onSave={handleSave}/>}

    </>);
};


export default AvatarUpload;
