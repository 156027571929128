import axios from "axios";

export const loadIntegrations = (callback) => {
    const config = {
        url: '/integrations',
    };

    axios.request(config).then(
        response => {
            callback(response.data);
        }
    );
};

export const saveIntegration = (data, callback) => {
    const config = {
        method: data.id ? 'PUT' : 'POST',
        url: data.id ? `/integrations/${data.id}` : '/integrations',
        data: data
    };
    axios(config).then(
        response => {
            callback(response.data);
        }
    );
};

export const deleteIntegration = (id, callback) => {
    const config = {
        url: `/integrations/${id}`,
        method: 'DELETE'
    };
    axios.request(config).then(callback);
};
