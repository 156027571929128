import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import {startCase, toLower} from "lodash";
import StatusDot from "../../common/status/StatusDot";
import {formatTagName, reverseColor} from "../utils/utils";
import "./Filter.scss";
import classNames from "classnames";


const Filter = ({items, initialTab, onClick=()=>{} }) => {
    const [currentTab, setCurrentTab] = useState(initialTab);
    const navigate = useNavigate();

    const handleClick = (itemName) => {
        onClick(itemName);
        setCurrentTab(itemName);
    };
    const getItemStyle = (item) => {
        if (initialTab === item.name && item.color) {
            return {
                color: reverseColor(item.color),
                backgroundColor: item.color
            };
        }
        return {};
    };

    return (
        <div className={`filter`}>
            {items.map(item => (
                <div
                    className={classNames("filter-item", 'tab', {'active': toLower(currentTab) === toLower(item.name)})}
                    key={item.id || item.name}
                    onClick={() => handleClick(item.name)}
                    style={getItemStyle(item)}
                >
                    {item.color && <StatusDot status={item.last_status}/>}
                    <div>{formatTagName(item.name)}</div>
                </div>
            ))}
        </div>
    );
};

export default Filter;
