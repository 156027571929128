import React, {useEffect, useState} from "react";
import {useFormik} from "formik";
import * as Yup from "yup";
import {useNavigate, useParams} from "react-router-dom";
import * as actions from "./ClientCopyActions";
import CardHeader from "../../containers/card/CardHeader";
import FormField from "../../common/formField/FormField";
import {userIcon} from "../../common/icons/Icon";
import CardBody from "../../containers/card/CardBody";
import Card from "../../containers/card/Card";
import {requiredMessage} from "../../common/utils/utils";
import Loader from "../../common/loader/Loader";

const validationSchema = Yup.object({
    name: Yup.string().required(requiredMessage),
});

const ClientCopy = () => {
    const {id} = useParams();
    const [loading, setLoading] = useState(false);
    const [name, setName] = useState(null);
    const navigate = useNavigate();
    const initialValues = {
        name: "",
    };

    const loadClientInfo = () => {
        if (id) {
            setLoading(true);
            actions.loadClient(id, (data) => {
                setName(data.name);
                setLoading(false);
            });
        }
    };


    const onCancel = () => {
        navigate("/clients");
    };

    const saveClient = () => {
        setLoading(true);
        actions.copyClient(id, formik.values.name, () => {
            setLoading(false);
            navigate("/clients");
        });
    };

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: saveClient,
    });

    useEffect(() => {
        loadClientInfo();
    }, [id]);

    return (
        <Card className="client">
            {loading ? <Loader/> : (<>
                <CardHeader
                    pathnames={[['clients', "Clients"], [null, name + " - copy"]]}
                    onCancel={onCancel}
                    onSave={formik.handleSubmit}
                    icon={userIcon}
                    loading={loading}
                >
                </CardHeader>
                <CardBody className="form">
                    <form autoComplete="off" onSubmit={formik.handleSubmit}>
                        <FormField
                            id="name-input"
                            label="Client Name"
                            name="name"
                            value={formik.values.name}
                            handleChange={formik.handleChange}
                            handleBlur={formik.handleBlur}
                            touched={formik.touched.name}
                            errors={formik.errors.name}
                            isRequired={true}
                        />
                    </form>
                </CardBody>
            </>)}
        </Card>
    );
};

export default ClientCopy;
