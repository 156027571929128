import {Navigate, Route, Routes, useNavigate} from 'react-router-dom';
import Login from "././pages/./login/Login";
import ProtectedRouter from "./common/protectRoute/ProtectedRoute";

import Logout from "././pages/./login/Logout";
import Navbar from "./common/./navbar/Navbar";
import Users from "././pages/./users/Users";
import User from "././pages/./users/User";
import Jobs from "././pages/./jobs/Jobs";
import Job from "././pages/./jobs/Job";
import BuildsRouter from "./pages/builds/BuildsRouter";
import Build from "././pages/./builds/Build";
import SettingsProfile from "././pages/./settings/Profile";
import {useEffect} from "react";
import VersionSnackbar from "./common/versionSnackbar/VersionSnackbar";
import {useSelector} from "react-redux";
import Group from "./pages/groups/Group";
import Clients from "./pages/clients/Clients";
import Client from "./pages/clients/Client";
import {useAuth} from "./context/AuthContext/AuthContext";
import BuildsJobs from "./pages/builds/BuildsJobs";
import Logs from "./pages/logs/Logs";
import Groups from "./pages/settings/Groups";
import Integrations from "./pages/settings/Integrations";
import Ldap from "./pages/settings/Ldap";
import ClientCopy from "./pages/clients/ClientCopy";

function App() {
    const {user} = useAuth()
    const token = localStorage.getItem('token')
    const navigate = useNavigate();
    const {appVersionMismatch, appVersion} = useSelector(state => state.app);

    const roleRedirects = {
        admin: "/users",
        superadmin: "/clients",
        user: "/jobs"
    };


    useEffect(() => {
        if (!token) {
            navigate("/login")
        }
    }, [token]);

    return (
        <div className="app">
            <Navbar/>
            {appVersion && appVersionMismatch && <VersionSnackbar/>}
            <Routes>
                <Route path="/login" element={user ? <Navigate to="/"/> : <Login/>}/>
                <Route path="/logout" element={user ? <Logout/> : <Navigate to="/"/>}/>

                <Route
                    path="/"
                    element={user && <Navigate to={roleRedirects[user.role]}/>}
                />

                <Route
                    path="/users"
                    element={<ProtectedRouter component={Users} roles={['admin']}/>}
                />
                <Route
                    path="/logs"
                    element={<ProtectedRouter component={Logs} roles={['admin']}/>}
                />
                <Route
                    path="/user/:id?"
                    element={<ProtectedRouter component={User} roles={['admin']}/>}
                />
                <Route
                    path="/jobs"
                    element={<ProtectedRouter component={Jobs} roles={['admin', 'user']}/>}
                />
                <Route
                    path="/job/:slug?"
                    element={<ProtectedRouter component={Job} roles={['admin', 'user']}/>}
                />
                <Route
                    path="/builds"
                    element={<ProtectedRouter component={BuildsJobs} roles={['user', 'admin']}/>}
                />
                <Route
                    path="/builds/:jobSlug/:tag?"
                    element={<ProtectedRouter component={BuildsRouter} roles={['user', 'admin']}/>}
                />
                <Route
                    path="/build/:jobSlug/:tag/:id"
                    element={<ProtectedRouter component={Build} roles={['user', 'admin']}/>}
                />
                <Route
                    path="/settings/profile"
                    element={<ProtectedRouter component={SettingsProfile} roles={['admin', 'user', 'superadmin']}/>}
                />
                <Route
                    path="/settings/groups"
                    element={<ProtectedRouter component={Groups} roles={['admin']}/>}
                />
                <Route
                    path="/settings/integrations"
                    element={<ProtectedRouter component={Integrations} roles={['admin']}/>}
                />
                <Route
                    path="/settings/ldap"
                    element={<ProtectedRouter component={Ldap} roles={['admin']}/>}
                />
                <Route
                    path="/settings/group/:id?"
                    element={<ProtectedRouter component={Group} roles={['admin']}/>}
                />
                <Route
                    path="/clients"
                    element={<ProtectedRouter component={Clients} roles={['superadmin']}/>}
                />
                <Route
                    path="/client/:tab"
                    element={<ProtectedRouter component={Client} roles={['superadmin']}/>}
                />
                <Route
                    path="/client/:id/copy"
                    element={<ProtectedRouter component={ClientCopy} roles={['superadmin']}/>}
                />
                <Route
                    path="/client/:id/:tab"
                    element={<ProtectedRouter component={Client} roles={['superadmin']}/>}
                />
                <Route
                    path="/client/:clientId?/user/:id?"
                    element={<ProtectedRouter component={User} roles={['superadmin']}/>}
                />
            </Routes>


        </div>
    );
}

export default App;
