import React, {useEffect} from "react";
import CancelSaveButtons from "../../../common/buttons/CancelSaveButtons";
import {useFormik} from "formik";
import * as Yup from "yup";
import Modal from "../../../common/modals/Modal";
import FormField from "../../../common/formField/FormField";
import ModalHeader from "../../../common/modals/ModalHeader";
import ModalBody from "../../../common/modals/ModalBody";
import ModalFooter from "../../../common/modals/ModalFooter";
import {requiredMessage,formikDefaults} from "../../../common/utils/utils";


const validationSchema = Yup.object({
    name: Yup.string().required(requiredMessage),
    color: Yup.string().required(requiredMessage),
})

const TagModal = ({onCancel, tag, onSave}) => {
    const initialValues = tag;

    const formik = useFormik({
        ...formikDefaults,
        initialValues,
        validationSchema,
        onSubmit: (values) => {
            onSave(values);
        }
    });

    return (
        <Modal
            isOpen={!!tag}
            onClose={onCancel}
            size="lg"
            height='fit-content'
            className='tag'
        >
            <ModalHeader title="Tag" value={tag.name || "New"}/>
            <ModalBody>
                <form autoComplete="off">
                    <FormField
                        id="name-input"
                        label="Name"
                        name="name"
                        value={formik.values.name}
                        handleChange={formik.handleChange}
                        handleBlur={formik.handleBlur}
                        touched={formik.touched.name}
                        errors={formik.errors.name}
                    />
                    <FormField
                        label="Color"
                        name="color"
                        type="color"
                        className='color-input'
                        value={formik.values.color}
                        handleChange={formik.handleChange}
                        handleBlur={formik.handleBlur}
                        touched={formik.touched.color}
                        errors={formik.errors.color}
                    />
                </form>
            </ModalBody>
            <ModalFooter>
                <CancelSaveButtons onCancel={onCancel} onSave={formik.handleSubmit}/>
            </ModalFooter>
        </Modal>
    );
}

export default TagModal;