import React, {useEffect, useMemo, useState} from "react";
import * as actions from "./ClientsActions";
import {useNavigate} from "react-router-dom";
import Icon, {copyIcon, userIcon} from "../../common/icons/Icon";
import Table from "../../common/tables/Table";
import CardHeader from "../../containers/card/CardHeader";
import Card from "../../containers/card/Card";
import TableActions from "../../common/buttons/TableActions";
import Button from "../../common/buttons/Button";

const Clients = () => {
    const navigate = useNavigate();
    const [clients, setClients] = useState([]);
    const [loading, setLoading] = useState(true);

    const refreshList = () => {
        actions.loadClients({}, (data) => {
            setClients(data);
            setLoading(false);
        });
    };

    const deleteAction = (client) => {
        actions.deleteClient(client.id, () => refreshList());
    };

    const copyAction = (client) => {
        navigate(`/client/${client.id}/copy`);
    }

    const editAction = (client) => {
        navigate(`/client/${client.id}/client`);
    };

    const handleAddClient = () => {
        navigate(`/client/client`);
    };

    useEffect(() => {
        refreshList();
    }, []);

    const columns = useMemo(
        () => [
            {
                Header: "Client Name",
                accessor: "name",
                Cell: ({row}) => (
                    <div className="row-name">
                        <p>{row.original.name}</p>
                    </div>
                ),
            },
            {
                Header: "Actions",
                Cell: ({row}) => (
                    <TableActions
                        row={row.original}
                        name="Client"
                        rowName={row.original.name}
                        onEdit={editAction}
                        onDelete={deleteAction}
                        customButtons={
                            !row.original.ldap_connected &&
                            <Button size='icon' onClick={() => copyAction(row.original)}>
                                <Icon icon={copyIcon}/>
                            </Button>}
                    />
                ),
            },
        ],
        [clients]
    );

    return (
        <Card className="clients">
            <CardHeader pathnames={[[null, "Clients"]]} onAdd={handleAddClient} icon={userIcon}/>
            <Table columns={columns} data={clients} loading={loading}/>
        </Card>
    );
};

export default Clients;
