import Modal from "../../../common/modals/Modal";
import FormField from "../../../common/formField/FormField";
import {useFormik} from "formik";

import "./ConnectLdapModal.scss"

import * as Yup from "yup";
import React, {useState} from "react";
import LdapWarningModal from "./LdapWarningModal";
import Button from "../../../common/buttons/Button";
import ModalHeader from "../../../common/modals/ModalHeader";
import ModalFooter from "../../../common/modals/ModalFooter";
import ModalBody from "../../../common/modals/ModalBody";
import {requiredMessage,formikDefaults} from "../../../common/utils/utils";

const validationSchema = Yup.object({
    ldap_connection_url: Yup.string().required(requiredMessage),
    base_dn: Yup.string().required(requiredMessage),
    user_base_dn: Yup.string().required(requiredMessage),
    group_base_dn: Yup.string().required(requiredMessage),
    admin_dn: Yup.string().required(requiredMessage),
    admin_password: Yup.string().required(requiredMessage),
})

const ConnectLdapModal = ({ldap, onSave, onClose}) => {
    const [showWarningModal, setShowWarningModal] = useState(false)
    const [loading, setLoading] = useState(false);

    const formik = useFormik({
        ...formikDefaults,
        onSubmit: () => {
            setShowWarningModal(true)
        },
        validationSchema,
        initialValues: {...ldap, admin_password: ""} || {
            ldap_connection_url: "",
            base_dn: "",
            user_base_dn: "",
            group_base_dn: "",
            admin_dn: "",
            admin_password: "",
        }
    })

    const connectToLdap = () => {
        setLoading(true)
        onSave(formik.values, (data) => {
            if (data.response.status === 422) {
                formik.setErrors({
                    ldap: "Cannot connect to LDAP: invalid data"
                })
            }
            setLoading(false)
        })
        setShowWarningModal(false)
    }

    return (
        <>
            <Modal onClose={!loading && onClose} className="connect-ldap-modal" height="fit-content" size='md'>
                <ModalHeader title="Connecting" value="LDAP"/>
                <ModalBody>
                    {formik.values && (
                        <form autoComplete="off">
                            <FormField id="ldap_connection_url" name="ldap_connection_url"
                                       label="LDAP connection url"
                                       value={formik.values.ldap_connection_url}
                                       errors={formik.errors.ldap_connection_url}
                                       touched={formik.touched.ldap_connection_url}
                                       handleChange={formik.handleChange}
                                       isRequired/>
                            <FormField id="base_dn" name="base_dn" label="Base DN"
                                       value={formik.values.base_dn}
                                       errors={formik.errors.base_dn}
                                       touched={formik.touched.base_dn}
                                       handleChange={formik.handleChange}
                                       isRequired/>
                            <FormField id="user_base_dn"
                                       name="user_base_dn"
                                       label="User Base DN"
                                       value={formik.values.user_base_dn}
                                       errors={formik.errors.user_base_dn}
                                       touched={formik.touched.user_base_dn}
                                       handleChange={formik.handleChange}
                                       isRequired/>
                            <FormField
                                id="group_base_dn"
                                name="group_base_dn"
                                label="Group Base DN"
                                value={formik.values.group_base_dn}
                                errors={formik.errors.group_base_dn}
                                touched={formik.touched.group_base_dn}
                                handleChange={formik.handleChange}
                                isRequired/>
                            <FormField
                                id="admin_dn"
                                name="admin_dn"
                                label="Admin DN"
                                value={formik.values.admin_dn}
                                errors={formik.errors.admin_dn}
                                touched={formik.touched.admin_dn}
                                handleChange={formik.handleChange}
                                isRequired/>
                            <FormField
                                id="admin_password"
                                name="admin_password"
                                label="Admin password"
                                value={formik.values.admin_password}
                                errors={formik.errors.admin_password}
                                touched={formik.touched.admin_password}
                                handleChange={formik.handleChange}
                                type="password"
                                isRequired
                            />
                            {formik.errors.ldap && <div className='error'>
                                {formik.errors.ldap}
                            </div>}
                        </form>
                    )}
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={onClose} disabled={loading}>Cancel</Button>
                    <Button color="primary" onClick={formik.handleSubmit} loading={loading}>Connect</Button>
                </ModalFooter>
            </Modal>
            {showWarningModal &&
                <LdapWarningModal onClose={() => setShowWarningModal(false)} onContinue={connectToLdap}/>}
        </>
    )
}

export default ConnectLdapModal