import React from 'react';
import Modal from "../../common/modals/Modal";
import Button from "../../common/buttons/Button";
import ModalHeader from "../../common/modals/ModalHeader";
import ModalBody from "../../common/modals/ModalBody";
import "./TokensModal.scss"
import ModalFooter from "../../common/modals/ModalFooter";

const TokensModal = ({job, onClose}) => {
    return (
        <Modal
            onClose={onClose}
            size="md"
            height="fit-content"
            className="tokens"
        >
            <ModalHeader title="Jobs" value={job.name}/>
            <ModalBody>
                <div>
                    {job.api_tokens.map((token, index) => (
                        <div className="token" key={index}>
                            <div className="label">{token.username}</div>
                            <div className="value">{token.api_token}</div>
                        </div>
                    ))}
                </div>
            </ModalBody>
            <ModalFooter>
                <Button
                    color="secondary"
                    onClick={() => onClose()}>
                    Close
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default TokensModal;
