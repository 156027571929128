import './StatusBar.scss';
import {statusColors} from "../utils/constants";
import _ from "lodash";

const StatusBar = ({statusCounts}) => {
    const total = _.sumBy(statusCounts, "count");

    return (
        <div className="status-bar">
            {statusCounts.map((el, i) =>
                <div key={i} style={{width: `${el.count / total * 100}%`, background: statusColors[el.status]}}/>
            )}
        </div>
    );
};

export default StatusBar;
