import React from "react";
import classnames from "classnames";
import './Button.scss';
import Loader from "../loader/Loader";

const Button = ({children, className, onClick, color, size, disabled, loading, buttonType = "button"}) => {
    return <button className={classnames("button", className, color, size)}
                   disabled={disabled || loading}
                   onClick={e => {
                       if (onClick) {
                           e.preventDefault();
                           e.stopPropagation();
                           onClick();
                       }
                   }}
                   type={buttonType}
    >
        {loading? <Loader /> : children}
    </button>
}
export default Button;