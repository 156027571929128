import React, {useEffect, useMemo, useState} from 'react';
import * as actions from "./LogsActions"
import Table from "../../common/tables/Table";
import CardHeader from "../../containers/card/CardHeader";
import Card from "../../containers/card/Card";
import {logsIcon} from "../../common/icons/Icon";
import CardBody from "../../containers/card/CardBody";


const Logs = () => {
    const [logs, setLogs] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        actions.loadLogs(logs => {
            setLogs(logs);
            setLoading(false);
        });

    }, []);


    const columns = useMemo(() => [
            {
                Header: 'Request',
                accessor: 'request'
            },
            {
                Header: 'Response',
                accessor: 'response'
            }
        ]
        , [logs]);

    return (
        <Card className="logs">
            <CardHeader pathnames={[[null, "Logs"]]} icon={logsIcon}/>
            <div className="main">
                <CardBody>
                    <Table columns={columns} data={logs} loading={loading}/>
                </CardBody>
            </div>
        </Card>
    );
}

export default Logs;
