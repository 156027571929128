import React from "react";
import {useFormik} from 'formik';
import * as Yup from 'yup';
import "./Login.scss";
import Button from "../../common/buttons/Button";
import FormField from "../../common/formField/FormField";
import { useAuth } from "../../context/AuthContext/AuthContext";
import {requiredMessage,formikDefaults} from "../../common/utils/utils";

const validationSchema = Yup.object({
    username: Yup.string().required(requiredMessage),
    password: Yup.string().required(requiredMessage),
});

const Login = () => {
    const { login } = useAuth();

    const formik = useFormik({
        ...formikDefaults,
        initialValues: {
            username: '',
            password: ''
        },
        validationSchema,
        onSubmit: (credentials) => {
            login(credentials, (error) => {
                if (error.response?.status === 401) {
                    formik.setErrors({
                        username: "Invalid username or password",
                        password: "Invalid username or password",
                        login: "Invalid username or password"
                    });
                } else {
                    formik.setErrors({
                        login: "Something went wrong"
                    });
                }
            });
        },
    });


    return (
        <div className='login'>
            <form onSubmit={formik.handleSubmit}>
                <div className='app-name'>
                    <div className='name'>
                        picklepot
                    </div>
                </div>
                <div className="content">
                    <div className='title'>
                        Sign in to your account
                    </div>
                    <FormField
                        id="username-input"
                        label="Username"
                        name="username"
                        value={formik.values.username}
                        handleChange={formik.handleChange}
                        handleBlur={formik.handleBlur}
                        touched={formik.touched.username}
                        errors={formik.errors.username !== "Invalid username or password" && formik.errors.username}

                    />
                    <FormField
                        id="password-input"
                        label="Password"
                        name="password"
                        value={formik.values.password}
                        handleChange={formik.handleChange}
                        handleBlur={formik.handleBlur}
                        touched={formik.touched.password}
                        errors={formik.errors.password !== "Invalid username or password" && formik.errors.password}
                        type="password"
                    />
                    {formik.errors.login && <div className='error'>
                        {formik.errors.login}
                    </div>}
                    <div className='buttons'>
                        <Button color="primary" onClick={formik.handleSubmit} buttonType="submit">Login</Button>
                    </div>
                </div>
            </form>
        </div>
    );
}

export default Login;
