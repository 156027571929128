import React, {useMemo, useState} from "react";
import Table from "../tables/Table";
import './Scenario.scss';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import StatusBar from "../status/StatusBar";
import StatusDot from "../status/StatusDot";
import Icon, {breadcrumbArrowIcon} from "../icons/Icon";
import {calculateTotalTime, formatDuration, getFeatureTotalTime, groupStatuses} from "../utils/utils";

dayjs.extend(duration);

const Scenarios = ({feature, filters, onScenarioClick}) => {
    const [activeRowIndex, setActiveRowIndex] = useState(null);
    const [activeRowTabsCount, setActiveRowTabsCount] = useState(0);

    const toggleRow = (rowIndex) => {

        setActiveRowIndex(prevIndex => {
            if (prevIndex === rowIndex) {
                return null;
            }
            return activeRowIndex !== null ? Math.max(0, rowIndex - activeRowTabsCount) : rowIndex
        })

    };


    const data = useMemo(() => {
        return feature
            .filter(item => (item.status === filters.status || filters.status === null) &&
                (item.name.toLocaleLowerCase().includes(filters.name?.toLocaleLowerCase()) || filters.name === null))
            .flatMap((row, index) => {
                const totalTime = getFeatureTotalTime(row);
                const mainRow = {...row, isMainRow: true, totalTime};

                if (activeRowIndex === index) {
                    const scenarioRows = row.scenarios?.filter(scenario => (scenario.status === filters.status || filters.status === null)).map(scenario => ({
                        isChildRow: true,
                        feature: row,
                        scenario: scenario.name || "Unnamed",
                        key: `scenario-${index}-${scenario.name}`,
                        totalTime: calculateTotalTime(scenario.steps),
                        steps: scenario.steps,
                        status: scenario.status,
                        feature_status: row.status
                    })) || [];
                    setActiveRowTabsCount(scenarioRows.length);

                    return [mainRow, ...scenarioRows];
                }

                return [mainRow];
            });
    }, [feature, activeRowIndex, filters]);

    const columns = [
        {
            Header: 'Name',
            accessor: 'name',
            Cell: ({row}) => (
                <div className='row-name' onClick={() => toggleRow(row.index)}>
                    {row.original.isMainRow ? (
                        <>
                            <Icon icon={breadcrumbArrowIcon} className={activeRowIndex === row.index ? "active" : ""}/>
                            <StatusDot status={row.original.status}/>
                        </>
                    ) : null}
                    <p>{row.original.name}</p>
                </div>
            ),
        },
        {
            Header: '',
            accessor: 'scenario',
            Cell: ({row}) => (
                row.original.isChildRow
                    ? <div className='extra-row' onClick={() => onScenarioClick(row.original)}>
                        <StatusDot status={row.original.status}/>
                        <p>{row.original.scenario}</p>
                    </div> : null
            ),
        },
        {
            Header: 'Total Time',
            accessor: 'totalTime',
            Cell: ({row}) => (
                <div className='row-time'>{formatDuration(row.original.totalTime || 0)}</div>
            ),
        },
        {
            Header: "Status",
            accessor: 'status',
            Cell: ({row}) => (
                row.original.isMainRow ? (
                    <StatusBar statusCounts={groupStatuses(row.original.scenarios)}/>
                ) : null
            ),
        }
    ];

    return (
        <div className='scenario'>
            <Table columns={columns}
                   data={data}/>
        </div>
    );
};

export default Scenarios;
