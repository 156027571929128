import React, {useEffect, useState} from "react";
import {useFormik} from "formik";
import * as Yup from "yup";
import {useNavigate, useParams} from "react-router-dom";
import * as actions from "./GroupActions";
import CardHeader from "../../containers/card/CardHeader";
import Card from "../../containers/card/Card";
import {settingsIcon} from "../../common/icons/Icon";
import Loader from "../../common/loader/Loader";
import CardBody from "../../containers/card/CardBody";
import FormField from "../../common/formField/FormField";
import {formikDefaults, requiredMessage} from "../../common/utils/utils";

const validationSchema = Yup.object({
    name: Yup.string().required(requiredMessage),
});

const Group = () => {
    const {id} = useParams()
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [name, setName] = useState(null);

    const initialValues = {
        name: "",
        admin: false
    };

    const saveGroup = (values) => {
        actions.saveGroup(values, () => {
            navigate("/settings/groups");
        });
    };

    const onCancel = () => {
        navigate("/settings/groups");
    };

    const formik = useFormik({
        ...formikDefaults,
        initialValues,
        validationSchema,
        onSubmit: saveGroup,
    });

    useEffect(() => {
        if (id) {
            actions.loadGroup(id, group => {
                formik.setValues(group);
                setName(group.name);
                setLoading(false);
            });
        } else {
            setLoading(false);
        }
    }, []);

    return (
        <Card className="group">
            <CardHeader
                pathnames={[["settings/groups", "Group"], [null, name || "New"]]}
                onCancel={onCancel}
                onSave={formik.handleSubmit}
                icon={settingsIcon}
                loading={loading}
            />
            {loading ? (
                <Loader/>
            ) : (
                <CardBody className="form">
                    <form autoComplete="off">
                        <FormField
                            id="name-input"
                            label="Name"
                            name="name"
                            value={formik.values.name}
                            handleChange={formik.handleChange}
                            handleBlur={formik.handleBlur}
                            touched={formik.touched.name}
                            errors={formik.errors.name}
                            disabled={formik.values.ldap_group}
                        />
                        <FormField
                            type="checkbox"
                            label="Admin group"
                            id="admin-input"
                            name="admin"
                            checked={formik.values.admin}
                            handleChange={formik.handleChange}
                            handleBlur={formik.handleBlur}
                            touched={formik.touched.admin}
                            errors={formik.errors.admin}
                        />
                    </form>
                </CardBody>
            )}
        </Card>
    );
};

export default Group;
