import React from 'react';
import {Line} from 'react-chartjs-2';
import {
    CategoryScale,
    Chart as ChartJS,
    Legend,
    LinearScale,
    LineElement,
    PointElement,
    Title,
    Tooltip,
} from 'chart.js';
import dayjs from "dayjs";
import {formatDuration} from "../../common/utils/utils";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

const LineChart = ({data}) => {
    const sorted = [...data].sort((a, b) => dayjs(a.when) - dayjs(b.when));
    const labels = sorted.map(item => dayjs(item.when).format('DD/MM'));

    const dataTime = sorted.map(item => item.took ? item.took / 60 : 0);

    const dataConfig = {
        labels: labels,
        datasets: [
            {
                label: 'Build Time',
                data: dataTime,
                fill: false,
                borderColor: '#616161',
                tension: 0,
                pointRadius: 1.5
            },
        ],
    };

    const options = {
        responsive: true,
        plugins: {
            legend: {
                display: false,
            },
            tooltip: {
                callbacks: {
                    label: function (tooltipData) {
                        return tooltipData.dataset.label + ": " + formatDuration(tooltipData.raw * 60);
                    },
                },
            }
        },
        scales: {
            y: {
                ticks: {
                    callback: function (value) {
                        return value + 'm';
                    },
                },
                min: 0,
                max: 50,
            },
        }
    };

    return (
        <div className="section line">
            <div className="chart-container">
                <div className="label">Build Time</div>
                <div className="chart">
                    <Line data={dataConfig} options={options}/>
                </div>
            </div>
        </div>
    );
};

export default LineChart;
