import React, {useEffect, useState} from "react";
import {useFormik} from 'formik';
import * as Yup from 'yup';
import {useNavigate, useParams} from "react-router-dom";
import * as actions from "./UserActions";
import "./User.scss";
import CardHeader from "../../containers/card/CardHeader";
import _ from "lodash";
import Card from "../../containers/card/Card";
import {userGroupIcon, userIcon} from "../../common/icons/Icon";
import Loader from "../../common/loader/Loader";
import FormField from "../../common/formField/FormField";
import CardBody from "../../containers/card/CardBody";
import {requiredMessage, formikDefaults} from "../../common/utils/utils";

const validationSchema = Yup.object({
    id: Yup.string(),
    username: Yup.string().required(requiredMessage),
    password: Yup.string()
        .min(6, 'Must be at least 6 characters')
        .when(['id', 'change_password'], {
            is: (id, change_password) => id === undefined || change_password,
            then: (schema) => schema.required(requiredMessage),
            otherwise: (schema) => schema.notRequired(),
        }),
    confirm_password: Yup.string()
        .oneOf([Yup.ref('password'), null], 'Passwords must match')
        .when(['id', 'change_password'], {
            is: (id, change_password) => id === undefined || change_password,
            then: (schema) => schema.required(requiredMessage),
            otherwise: (schema) => schema.notRequired(),
        }),
    role: Yup.string().required(requiredMessage)
});


const User = () => {
    const {id, clientId} = useParams();
    const navigate = useNavigate();
    const role = localStorage.getItem('role');
    const [jenkinsToken, setJenkinsToken] = useState('');
    const [loading, setLoading] = useState(false);
    const [name, setName] = useState(null);
    const [clientName, setClientName] = useState(null);
    const initialValues = {
        username: '',
        password: '',
        confirm_password: '',
        client_id: clientId,
        role: ''
    };
    const roleOptions = [
        {value: "admin", label: "admin"},
        {value: "user", label: "user"},
    ];

    const superadmin = role === "superadmin";

    const onSaveCancelPath = superadmin ? `/client/${clientId}/users` : "/users";
    const saveUser = (values) => {
        if (!values.change_password && values.id) {
            values = _.omit(values, ['password']);
        }

        values = _.omit(values, ['change_password', 'confirm_password']);

        actions.saveUser(values, () => {
            navigate(onSaveCancelPath);
        });
    };

    const onCancel = () => {
        navigate(onSaveCancelPath);
    }

    const formik = useFormik({
        ...formikDefaults,
        initialValues,
        validationSchema,
        onSubmit: saveUser
    });

    const loadUser = () => {
        actions.loadUser(id, (data) => {
            formik.setValues(data);
            setName(data.username);
            setJenkinsToken(data.jenkins_token);
            setLoading(false);
        });
    }

    function initializeForm() {
        if (id) {
            loadUser();
        } else {
            setLoading(false);
        }
    }

    useEffect(() => {
        setLoading(true)
        if (superadmin) {
            actions.loadClient(clientId, client => {
                setClientName(client.name);
                initializeForm()
            });
        } else {
            initializeForm();
        }
    }, [id]);

    const mapRoleToOption = (role) => {
        return roleOptions.find(option => option.value === role) || null;
    };

    const handleChangeSelect = (selectedOption) => {
        formik.setFieldValue("role", selectedOption.value);
    };

    let breadcrumbs = superadmin ?
        [["clients", "Clients"], [`client/${clientId}/client`, clientName], [`client/${clientId}/users`, "Users"], [null, name || "New"]] :
        [["users", "Users"], [null, name || "New"]];
    return (
        <Card className='user'>
            <CardHeader
                pathnames={breadcrumbs}
                onCancel={onCancel}
                onSave={formik.handleSubmit}
                icon={superadmin ? userIcon : userGroupIcon}
                loading={loading}
            />
            <CardBody className="form">
                {loading ? <Loader/> :
                    <form autoComplete="off">
                        <FormField
                            id="username-input"
                            label="Username"
                            name="username"
                            value={formik.values.username}
                            handleChange={formik.handleChange}
                            handleBlur={formik.handleBlur}
                            touched={formik.touched.username}
                            errors={formik.errors.username}
                            isRequired={!formik.values.ldap_user}
                            disabled={formik.values.ldap_user}

                        />
                        {formik.values.id && !formik.values.ldap_user &&
                            <FormField
                                id="change-password-input"
                                label='Change password'
                                type='checkbox'
                                name='change_password'
                                value={formik.values.change_password}
                                handleChange={formik.handleChange}
                                handleBlur={formik.handleBlur}
                                touched={formik.touched.change_password}
                                errors={formik.errors.change_password}

                            />
                        }
                        {(formik.values.change_password || !formik.values.id) &&
                            <>
                                <FormField
                                    id="password-input"
                                    label="Password"
                                    type="password"
                                    name="password"
                                    autoComplete="new-password"
                                    value={formik.values.password}
                                    handleChange={formik.handleChange}
                                    handleBlur={formik.handleBlur}
                                    touched={formik.touched.password}
                                    errors={formik.errors.password}
                                    isRequired={true}
                                />
                                <FormField
                                    id="confirm-password-input"
                                    label="Confirm password"
                                    type="password"
                                    name="confirm_password"
                                    value={formik.values.confirm_password}
                                    handleChange={formik.handleChange}
                                    handleBlur={formik.handleBlur}
                                    touched={formik.touched.confirm_password}
                                    errors={formik.errors.confirm_password}
                                    isRequired={true}
                                />
                            </>}
                        <FormField
                            id="role-input"
                            label="Role"
                            name="role"
                            value={mapRoleToOption(formik.values.role)}
                            handleChange={handleChangeSelect}
                            handleBlur={formik.handleBlur}
                            touched={formik.touched.role}
                            errors={formik.errors.role}
                            type="select"
                            options={roleOptions}
                            isRequired={!formik.values.ldap_user}
                            disabled={formik.values.ldap_user}
                        />
                    </form>
                }
            </CardBody>
        </Card>
    );
}

export default User;
