import React from 'react';
import _ from 'lodash';
import './ModalHeader.scss';

const ModalHeader = ({title, value}) => {
    return <div className='header'>
        <div className="title">{_.capitalize(title)}:</div>
        <div className="value">{value}</div>
    </div>
}

export default ModalHeader;