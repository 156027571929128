import React, {useEffect, useState} from "react";
import {useFormik} from "formik";
import * as Yup from "yup";
import {useNavigate, useParams} from "react-router-dom";
import * as actions from "./ClientActions";
import CardHeader from "../../containers/card/CardHeader";
import Table from "../../common/tables/Table";
import FormField from "../../common/formField/FormField";
import {userIcon} from "../../common/icons/Icon";
import TableActions from "../../common/buttons/TableActions";
import Filter from "../../common/filter/Filter";
import CardBody from "../../containers/card/CardBody";
import Card from "../../containers/card/Card";
import {requiredMessage, formikDefaults} from "../../common/utils/utils";

const validationSchema = Yup.object({
    name: Yup.string().required(requiredMessage),
});

const Client = () => {
    const {id, tab} = useParams();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [users, setUsers] = useState([]);
    const [name, setName] = useState(null);
    const loggedInUsername = localStorage.getItem('username');
    const tabs = id
        ? [{name: "Client"}, {name: "Users"}]
        : [{name: "Client"}];

    const initialValues = {
        name: "",
    };

    const refreshList = () => {
        if (id) {
            setLoading(true);
            actions.loadClient(id, (data) => {
                formik.setValues(data);
                setName(data.name);
                setUsers(data.users || []);
                setLoading(false);
            });
        }
    };

    const saveClient = (values) => {
        actions.saveClient(values, () => {
            navigate("/clients");
        });
    };

    const onCancel = () => {
        navigate("/clients");
    };

    const deleteUser = (user) => {
        actions.deleteUser(user.id, () => {
            setUsers((prevUsers) =>
                prevUsers.filter((u) => u.id !== user.id)
            );
        });
    };

    const editUser = (user) => {
        navigate(`/client/${id}/user/${user.id}`);
    };

    const addUser = () => {
        navigate(`/client/${id}/user`);
    };

    const formik = useFormik({
        ...formikDefaults,
        initialValues,
        validationSchema,
        onSubmit: saveClient,
    });

    useEffect(() => {
        refreshList();
    }, [id]);

    const columns = React.useMemo(
        () => [
            {
                Header: "Username",
                accessor: "username",
                Cell: ({row}) => (
                    <div className="row-username">
                        <p>{row.original.username}</p>
                    </div>
                ),
            },
            {
                Header: 'Role',
                accessor: 'role'
            },
            {
                Header: "Actions",
                Cell: ({row}) => (
                    <TableActions
                        row={row.original}
                        name="User"
                        onEdit={editUser}
                        rowName={row.original.username}
                        disableOnEdit={row.original.ldap_user}
                        disabledOnDelete={row.original.ldap_user}
                        onDelete={row.original.username !== loggedInUsername ? deleteUser : undefined}
                    />
                ),
            },
        ],
        [users]
    );
    return (
        <Card className="client">
            <CardHeader
                pathnames={[["clients", "Clients"], [null, name || "New"]]}
                onCancel={onCancel}
                onSave={tab === "client" && formik.handleSubmit}
                onAdd={tab === "users" && addUser}
                icon={userIcon}
                loading={loading}
                tabs={<Filter items={tabs}
                              onClick={itemName => {
                                  const linkElements = ["client"];
                                  if (id) {
                                      linkElements.push(id);
                                  }
                                  linkElements.push(itemName.toLocaleLowerCase());
                                  return navigate("/" + linkElements.join("/"));
                              }}
                              initialTab={tab} page="Client"/>}
            >
            </CardHeader>
            <>
                {tab === "client" ? (
                    <CardBody className="form">
                        <form autoComplete="off" onSubmit={formik.handleSubmit}>
                            <FormField
                                id="name-input"
                                label="Client Name"
                                name="name"
                                value={formik.values.name}
                                handleChange={formik.handleChange}
                                handleBlur={formik.handleBlur}
                                touched={formik.touched.name}
                                errors={formik.errors.name}
                                isRequired={true}
                            />
                        </form>
                    </CardBody>
                ) : (
                    <CardBody>
                        <div className="users-section">
                            <Table
                                columns={columns}
                                data={users}
                            />
                        </div>
                    </CardBody>
                )}
            </>
        </Card>
    );
};

export default Client;
