import React from 'react';
import {Doughnut} from 'react-chartjs-2';
import {ArcElement, Chart as ChartJS, Legend, Tooltip} from 'chart.js';
import classNames from "classnames";
import _ from "lodash";
import {statusColors, statusText} from "../../common/utils/constants";

ChartJS.register(ArcElement, Tooltip, Legend);

const customPlugin = {
    id: 'textInCenter',
    beforeDraw: (chart) => {
        const {ctx, chartArea} = chart;
        const {width, height} = chartArea;

        ctx.save();
        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';
        ctx.font = '17px Arial';

        const values = chart.data.datasets[0].data;
        const labels = chart.data.labels || [];
        const statuses = _.invert(statusText);

        const reorderedValues = values;
        const colors = labels.map(l => statusColors[statuses[l]]);

        const separator = " / ";
        const totalWidth = ctx.measureText(reorderedValues.join(separator)).width
        let offsetX = (width / 2) - totalWidth / 2

        reorderedValues.forEach((value, index) => {
            offsetX += ctx.measureText(value).width / 2
            ctx.fillStyle = colors[index];
            ctx.fillText(value, offsetX, height / 2);
            offsetX += ctx.measureText(value).width / 2

            if (index < reorderedValues.length - 1) {
                offsetX += ctx.measureText(separator).width / 2
                ctx.fillStyle = '#000';
                ctx.fillText(separator, offsetX, height / 2);
                offsetX += ctx.measureText(separator).width / 2
            }
        });

        ctx.restore();
    }
};

const PieChart = ({data, hasTotal}) => {
    const total = _.sumBy(data, "count");

    const colors = data.map(s => statusColors[s.status]);
    const dataConfig = {
        labels: data.map(s => statusText[s.status]),
        datasets: [
            {
                label: 'Colors Dataset',
                data: data.map(s => s.count),
                backgroundColor: colors,
                borderColor: colors,
                borderWidth: 1,
            },
        ],
    };

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'right',
                labels: {
                    font: {
                        size: 10,
                    },
                    usePointStyle: true,
                    pointStyle: 'circle',
                    boxWidth: 15,
                    padding: 25,
                },
            },
            tooltip: {
                enabled: true,
                callbacks: {
                    label: (chartData) => {
                        return chartData.parsed
                    }
                }
            },
        },
        cutout: '90%',
    };

    return (
        <div className={classNames('section', 'pie', {'without-total': !hasTotal})}>
            <div className="chart-container">
                {hasTotal && <div className="label"> {total} <span>Builds</span></div>}
                <div className="chart">
                    <Doughnut data={dataConfig} options={options} plugins={[customPlugin]}/>
                </div>
            </div>
        </div>
    );
};

export default PieChart;