import React, {useEffect, useState} from "react";
import {useFormik} from 'formik';
import * as Yup from 'yup';
import {useNavigate, useParams} from "react-router-dom";
import * as actions from "./JobActions";
import {loadContextData} from "./JobActions";
import "./Job.scss";
import CardHeader from "../../containers/card/CardHeader";
import Card from "../../containers/card/Card";
import {jobIcon} from "../../common/icons/Icon";
import Loader from "../../common/loader/Loader";
import _ from "lodash";
import TagsList from "./tags/TagsList";
import TagModal from "./tags/TagModal";
import CardBody from "../../containers/card/CardBody";
import FormField from "../../common/formField/FormField";
import JenkinsConnectionBox from "../../common/JenkinsConnectionBox/JenkinsConnectionBox";
import JenkinsJobModal from "./integrations/JenkinsJobModal";
import NoIntegration from "../../common/noIntegration/NoIntegration";
import {arrayMove, formikDefaults, handleNestedAttributes, handleSelect,requiredMessage} from "../../common/utils/utils";


const validationSchema = Yup.object({
    name: Yup.string().required(requiredMessage),
});

const Job = () => {
    const {slug} = useParams();
    const navigate = useNavigate();
    const [groups, setGroups] = useState([]);
    const [loading, setLoading] = useState(true);
    const [tag, setTag] = useState(null);
    const [name, setName] = useState(null);
    const [availableIntegrations, setAvailableIntegrations] = useState([]);
    const [selectedIntegration, setSelectedIntegration] = useState(null);
    const initialValues = {
        name: '',
        groups: [],
        tags_attributes: [],
    }

    const saveJob = (values) => {
        actions.saveJob({...values, group_ids: values.groups.map(g => g.value)}, () => navigate("/jobs"));
    };

    const onCancel = () => {
        navigate("/jobs");
    }

    const formik = useFormik({
        ...formikDefaults,
        initialValues,
        validationSchema,
        onSubmit: saveJob
    });

    const optionsMapper = (option) => ({value: option.id, label: option.name, ...option});
    const handleOpenTagModal = () => {
        setTag({name: '', color: '#FFFFFF'});
    }

    const handleSaveTag = (tag) => {
        if (slug) {
            actions.saveJob(_.pick({
                ...formik.values,
                tags_attributes: [...formik.values.tags_attributes, tag]
            }, 'tags_attributes', 'id', 'slug'), (newJob) => {
                formik.setFieldValue('tags_attributes', newJob.tags_attributes);
            });
        } else {
            handleNestedAttributes(formik, tag, "tags_attributes");
        }
        setTag(null);
    }

    const handleDeleteTag = (tag) => {
        if (slug) {
            actions.saveJob(_.pick({
                ...formik.values, tags_attributes: formik.values.tags_attributes.map(
                    (t) => t.id === tag.id ? {...t, _destroy: true} : t
                )
            }, 'tags_attributes', 'id', 'slug'), (newJob) => {
                formik.setFieldValue('tags_attributes', newJob.tags_attributes);
            });
        } else {
            formik.setFieldValue('tags_attributes', formik.values.tags_attributes.filter(
                (t) => t.index !== tag.index
            ));
        }
    }

    const handleEditTag = (tag) => {
        setTag(tag);
    }


    const handleJenkinsSave = (jenkins) => {
        Object.entries(jenkins).forEach(([key, value]) => formik.setFieldValue(key, value));

    }


    useEffect(() => {
        loadContextData(({groups, integrations}) => {
            setGroups(groups);
            setAvailableIntegrations(integrations);
            if (slug) {
                actions.loadJob(slug, (job) => {
                    setName(job.name);
                    formik.setValues({...job, groups: job.groups.map(optionsMapper)});
                    setLoading(false);
                });
            } else {
                setLoading(false);
            }
        });
    }, [slug]);


    return (
        <Card className='job'>
            {
                tag && <TagModal tag={tag}
                                 onCancel={() => setTag(null)}
                                 onSave={handleSaveTag}/>
            }
            <CardHeader
                pathnames={[["jobs", "Jobs"], [null, name || "New"]]}
                onCancel={onCancel}
                onSave={formik.handleSubmit}
                icon={jobIcon}
                loading={loading}
            />
            {loading ? <Loader/> : <CardBody className="form">
                <form autoComplete="off">
                    <FormField
                        id="name-input"
                        label="Name"
                        name="name"
                        value={formik.values.name}
                        handleChange={formik.handleChange}
                        handleBlur={formik.handleBlur}
                        touched={formik.touched.name}
                        errors={formik.errors.name}
                        isRequired={true}
                    />
                    <FormField
                        id="job-users-attributes-input"
                        label="Groups"
                        type="select"
                        name="groups"
                        value={formik.values.groups}
                        handleBlur={formik.handleBlur}
                        touched={formik.touched.groups}
                        errors={formik.errors.groups}
                        options={groups.map(optionsMapper)}
                        isMulti
                        handleChange={handleSelect(formik)}
                    />
                    <FormField
                        id="job-users-attributes-input"
                        label="Tags"
                        name="tags_attributes"
                        value={formik.values.tags_attributes}
                        handleChange={formik.handleChange}
                        handleBlur={formik.handleBlur}
                        touched={formik.touched.tags_attributes}
                        errors={formik.errors.tags_attributes}
                        renderInput={false}
                    >
                        <TagsList tags={formik.values.tags_attributes}
                                  onAdd={handleOpenTagModal}
                                  onDelete={handleDeleteTag}
                                  onEdit={handleEditTag}
                                  setTags={() => {
                                  }}
                                  onEnd={(event) => {
                                      const arr = formik.values.tags_attributes;
                                      arrayMove(arr, event.oldIndex, event.newIndex);
                                      const sortedTags = arr.map((el, i) => ({...el, order: i}));
                                      formik.setFieldValue('tags_attributes', sortedTags);
                                      if (slug) {
                                          actions.saveJob(_.pick({
                                              ...formik.values,
                                              tags_attributes: sortedTags
                                          }, 'tags_attributes', 'id', 'slug'), (newJob) => {
                                              formik.setFieldValue('tags_attributes', newJob.tags_attributes);
                                          });
                                      }
                                  }}
                        />
                    </FormField>

                    <FormField
                        id='integrations'
                        label='Integrations'
                        className="jenkins"
                        renderInput={false}
                    >
                        {availableIntegrations.length > 0 ? availableIntegrations.map((integration) => (
                            <JenkinsConnectionBox
                                key={integration.id}
                                isConnected={formik.values.integration_id === integration.id}
                                connectable={true}
                                name={integration.name}
                                clickAction={() => setSelectedIntegration(integration)}
                            />
                        )) : <NoIntegration/>}
                    </FormField>
                </form>
            </CardBody>
            }
            {selectedIntegration &&
                <JenkinsJobModal
                    onClose={() => setSelectedIntegration(undefined)}
                    onSave={handleJenkinsSave}
                    jenkinsJob={formik.values.integration_id === selectedIntegration.id ? _.pick(formik.values,
                            "integration_id", "jenkins_name", "jenkins_token")
                        : {integration_id: selectedIntegration.id}}
                    jenkinsName={selectedIntegration.name}
                />}
        </Card>
    );
}

export default Job;
