import React, {useState} from "react";
import "./ReportSection.scss";
import FormField from "../formField/FormField";
import SearchInput from "../searchInput/SearchInput";
import Scenarios from "./Scenarios";
import {stepResultText} from "../utils/constants";
import StatusOption from "./StatusOption";

const ReportSection = ({report, onScenarioClick}) => {

    const [filters, setFilters] = useState({
        status: null,
        name: null
    })

    const statusOptions = [
        {value: null, label: <StatusOption/>},
        ...Object.keys(stepResultText).map(status => ({
            value: status,
            label: <StatusOption label={stepResultText[status]}/>,
        }))
    ];

    return <div className='report-section'>
        <div className="filters">
            <FormField label={null} type="select" options={statusOptions}
                       handleChange={(e) => setFilters(prevFilters => (
                           {...prevFilters, status: e.value}
                       ))}/>
            <SearchInput type='text' handleChange={(e) => setFilters(prevFilters => (
                {...prevFilters, name: e.target.value}
            ))}/>
        </div>
        <div className='body'>
            <Scenarios
                filters={filters}
                feature={report.features} onScenarioClick={onScenarioClick}/>
        </div>
    </div>

};

export default ReportSection;