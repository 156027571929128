import axios from "axios";

export const copyClient = (id, newName, callback) => {
    const config = {
        url: `/clients/${id}/copy`,
        method: 'POST',
        data: {
            client:{
                name: newName
            }
        }
    };

    axios.request(config).then(
        response => {
            callback(response.data);
        }
    );
};

export const loadClient = (id, callback) => {
    const config = {
        url: `/clients/${id}`
    }

    axios.request(config).then(
        response => {
            callback( response.data);
        }
    )
}