import FormField from "../../common/formField/FormField";
import Button from "../../common/buttons/Button";

import "./Ldap.scss"
import ConnectLdapModal from "./ldap/ConnectLdapModal";
import React, {useEffect, useState} from "react";

import * as actions from "./LdapActions"
import SyncLdapModal from "./ldap/SyncLdapModal";
import DisconnectLdapModal from "./ldap/DisconnectLdapModal";
import CardBody from "../../containers/card/CardBody";
import Card from "../../containers/card/Card";
import {SettingsCardHeader} from "./SettingsCardHeader";

const Ldap = () => {
    const [showConnectLdapModal, setShowConnectLdapModal] = useState(false);
    const [showSyncLdapModal, setShowSyncLdapModal] = useState(false);
    const [showDisconnectModal, setShowDisconnectModal] = useState(false);
    const [ldapData, setLdapData] = useState(null);

    useEffect(() => {
        actions.getLdap((ldap_data) => {
            setLdapData(ldap_data)
        })
    }, [])

    const connectLdap = (data, onError) => {
        actions.connectLdap(data, (ldap) => {
            setLdapData(ldap)
            setShowConnectLdapModal(false)
        }, onError)
    }

    const disconnectLdap = (callback) => {
        actions.disconnectLdap(() => {
            setLdapData(null)
            setShowDisconnectModal(false)
            callback()
        })
    }

    return <Card className="settings groups">
        <SettingsCardHeader tab="ldap"/>
        <CardBody className="form">
            <div className="ldap">
                <FormField renderInput={false} label="Connect with LDAP">
                    {ldapData ? (
                        <>
                            <Button color="secondary" onClick={() => setShowConnectLdapModal(true)}>
                                Change data
                            </Button>
                            <Button color="secondary" onClick={() => setShowDisconnectModal(true)}>
                                Disconnect
                            </Button>
                        </>
                    ) : (
                        <Button color="secondary" onClick={() => setShowConnectLdapModal(true)}>
                            Connect
                        </Button>
                    )}

                </FormField>
                {ldapData && (
                    <FormField renderInput={false} label="Sync with LDAP">
                        <Button color="secondary" onClick={() => setShowSyncLdapModal(true)}>Sync</Button>
                    </FormField>
                )}
            </div>
            {showConnectLdapModal &&
                <ConnectLdapModal ldap={ldapData} onSave={connectLdap}
                                  onClose={() => setShowConnectLdapModal(false)}/>}

            {showSyncLdapModal &&
                <SyncLdapModal onClose={() => setShowSyncLdapModal(false)}
                               onSync={actions.syncLdap}/>}
            {showDisconnectModal &&
                <DisconnectLdapModal onDisconnect={disconnectLdap}
                                     onClose={() => setShowDisconnectModal(false)}/>}
        </CardBody>
    </Card>;

}

export default Ldap