import React from 'react';
import ReactModal from "react-modal";
import classnames from "classnames";
import './Modal.scss';


const Modal = ({children, onClose, size, className, height}) => {
    return (
        <ReactModal
            isOpen
            className={classnames("modal", className, size, height)}
            overlayClassName="overlay"
            onRequestClose={onClose}
            appElement={document.getElementById('root')}
        >
            {children}
        </ReactModal>
    );
}

export default Modal;