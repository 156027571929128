import ImageWithTransform from "../imageWithTransform/ImageWithTransform";
import {useEffect, useRef, useState} from "react";
import "./ImageDiffPreview.scss"
import Icon, {closeIcon} from "../icons/Icon";
import Button from "../buttons/Button";

const ImageDiffPreview = ({expected, diff, actual, onClose}) => {
    const transformRefs = [useRef(null), useRef(null), useRef(null)]
    const [zoomScale, setZoomScale] = useState(1)

    const hasDiffAndActual = diff && actual

    const getHeightStyle = () => {
        if (zoomScale > 5) {
            return {minHeight: "80vh"};
        } else if (zoomScale > 3) {
            return {minHeight: "75vh"};
        } else if (zoomScale > 2) {
            return {minHeight: "60vh"};
        } else if (zoomScale >= 1) {
            return {minHeight: "auto"};
        }
        return {};
    };

    const handleZoomPan = (transformData) => {
        const {positionX, positionY, scale} = transformData.state
        setTransforms(scale, positionX, positionY)
    }

    const setTransforms = (scale, positionX, positionY) => {
        transformRefs.forEach(ref => {
            if (ref.current) {
                ref.current.setTransform(positionX, positionY, scale, 0)
                setZoomScale(scale)
            }
        })
    }

    const handleClickBackground = (e) => {
        if (e.target.className === "row" || e.target.className === "header" || e.target.className === "content") {
            onClose()
        }
    }

    useEffect(() => {
        document.addEventListener("mousedown", handleClickBackground)
        return () => document.removeEventListener("mousedown", handleClickBackground)
    }, []);

    return (
        <div className="image-diff-preview">
            <div className="header">
                <Button className="close-button" size="icon" onClick={onClose}>
                    <Icon icon={closeIcon}/>
                </Button>
            </div>
            <div className="content">
                <div className="row">
                    <div className="image">
                        <span className="label">Actual</span>
                        <div className="image-with-transform">
                            <ImageWithTransform
                                src={actual}
                                alt=""
                                tranformRef={transformRefs[2]}
                                getHeightStyle={getHeightStyle}
                                onZoomPan={handleZoomPan}
                            />
                        </div>
                    </div>

                    <div className="image">
                        <span className="label">Different</span>
                        <div className="image-with-transform">
                            <ImageWithTransform
                                src={diff}
                                alt=""
                                tranformRef={transformRefs[1]}
                                getHeightStyle={getHeightStyle}
                                onZoomPan={handleZoomPan}
                            />
                        </div>
                    </div>

                    <div className="image">
                        {hasDiffAndActual && <span className="label">Expected</span>}
                        <div className="image-with-transform">
                            <ImageWithTransform
                                src={expected}
                                tranformRef={transformRefs[0]}
                                getHeightStyle={hasDiffAndActual && getHeightStyle}
                                onZoomPan={handleZoomPan}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ImageDiffPreview