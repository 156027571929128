import React from "react";
import {statusColors, statusText} from "../../common/utils/constants";
import './BuildStatus.scss'

const BuildStatus = ({label, status, time}) => {
    return <div className="build-status">
        <div className="label">{label}</div>
        {status ? (
            <div className="status" style={{color: statusColors[status]}}>{statusText[status]}</div>
        ) : (
            <div className="status">None</div>
        )}

        <div className="label small">{time && `(${time})`}</div>
    </div>;
}

export default BuildStatus;