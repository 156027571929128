import React from "react";
import './NoIntegration.scss';

const NoIntegration = () => {

    return (
        <div className="no-integration">
            <p>No integration available</p>
        </div>
    )
}

export default NoIntegration;