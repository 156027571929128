import React from "react";
import {ReactSortable} from "react-sortablejs";
import "./TagsList.scss"
import AddNewListItem from "../../../common/buttons/AddNewListItem";
import TableActions from "../../../common/buttons/TableActions";

const TagsList = ({tags, setTags, onEdit, onDelete, onAdd, onEnd}) => {
    return (
        <div className='tags-list'>
            <ReactSortable
                list={tags}
                setList={setTags}
                animation={200}
                onEnd={onEnd}
                className='tags'
            >
                {tags.map((tag) => (
                    <div key={tag.id} className='tag'>
                        <div className='info'>
                            <div className='color-preview'
                                 style={{backgroundColor: tag.color}}>
                            </div>
                            {tag.name}
                        </div>
                        <TableActions name='Tags' row={tag} rowName={tag.name} onEdit={onEdit} onDelete={onDelete}/>
                    </div>
                ))}
            </ReactSortable>
            <AddNewListItem onAdd={onAdd}/>
        </div>
    );
}

export default TagsList;