import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import './ProfileDropdown.scss';
import * as actions from "./ProfileDropdownActions";
import Icon, {logoutIcon, settingsIcon} from "../icons/Icon";
import Dropdown from "./Dropdown";
import Avatar from "../avatar/Avatar";
import {useContextProvider} from "../../context/ContextProvider/ContextProvider";
import {useAuth} from "../../context/AuthContext/AuthContext";
import {useSelector} from "react-redux";
import Initials from "../initials/Initials";

const ProfileDropdown = () => {
    const navigate = useNavigate();
    const {logout} = useAuth()
    const [avatar, setAvatar] = useState(null);
    const {profile, setProfile, reloadProfile} = useContextProvider();
    const username = localStorage.getItem('username');
    const role = localStorage.getItem('role');
    const {appVersion} = useSelector(state => state.app);


    const onOptionClicked = (path) => () => {
        if (path === 'logout') {
            logout()
            setProfile(null)
        } else {
            navigate(path);
        }
    };

    useEffect(() => {
        if (!profile) return;
        if (profile.picture_path) {
            actions.downloadAvatar('normal', (data) => {
                setAvatar(URL.createObjectURL(data));
            })
        } else {
            setAvatar(null)
        }

    }, [profile]);

    useEffect(() => {
        reloadProfile();
    }, []);

    return (
        <Dropdown
            className='profile' component={
            <div className='avatar-container'>
                {avatar && <Avatar image={avatar} size='small'/>}
                {!avatar && profile && <Initials profile={profile}/>}
                {username}
            </div>
        }
        >
            <ul className="list">
                <li className='item' onClick={onOptionClicked('/settings/profile')}>
                    <Icon icon={settingsIcon}/> Settings
                </li>
                <li className='item' onClick={onOptionClicked('logout')}>
                    <Icon icon={logoutIcon}/> Logout
                </li>
            </ul>

            {appVersion && <div className="version">
                v. {appVersion}
            </div>}
        </Dropdown>

    );
}

export default ProfileDropdown;
