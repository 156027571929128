import React, {useEffect, useMemo, useState} from 'react';
import {Tooltip} from 'react-tooltip';
import "./Builds.scss"
import * as actions from "./BuildsActions"
import {useNavigate} from "react-router-dom";
import Table from "../../common/tables/Table";
import CardHeader from "../../containers/card/CardHeader";
import {formatDuration} from "../../common/utils/utils";
import Card from "../../containers/card/Card";
import {buildIcon} from "../../common/icons/Icon";
import TableActions from "../../common/buttons/TableActions";
import CardBody from "../../containers/card/CardBody";
import CardReports from "../../containers/card/CardReports";
import StatusBar from "../../common/status/StatusBar";
import StatusDot from "../../common/status/StatusDot";
import dayjs from "dayjs";
import Filter from "../../common/filter/Filter";
import Initials from "../../common/initials/Initials";
import Avatar from "../../common/avatar/Avatar";

const relativeTime = require("dayjs/plugin/relativeTime")
dayjs.extend(relativeTime)

const Builds = ({jobSlug, tag}) => {
    const navigate = useNavigate();
    const [builds, setBuilds] = useState([]);
    const [buildsStates, setBuildsStates] = useState({
        loading: true,
        loadingTags: true,
        jobSlug
    });
    const [tags, setTags] = useState([]);
    const [jobName, setJobName] = useState(null);
    const selectedTag = tag;

    const refreshList = () => {
        actions.loadBuilds(jobSlug,
            {
                tag: selectedTag === "untagged" ? null : selectedTag
            },
            (data) => {
                setBuilds(data.builds);
                setJobName(data.job_name);
                setBuildsStates(prevState => ({...prevState, loading: false}));
            });
    }
    const deleteAction = (build) => {
        actions.deleteBuild(jobSlug, build.id, () => {
            setBuilds(builds.filter(b => b.id !== build.id));
        });
    }

    useEffect(() => {
        setBuildsStates(prevState => ({...prevState, loadingTags: true}));
        actions.loadTags(jobSlug, (data) => {
            setTags([...data.tags, {name: "untagged", color: "#737373", last_status: data.untagged_status}]);
            setBuildsStates(prevState => ({...prevState, loadingTags: false}));
            const defaultTag = data.tags.length > 0 ? data.tags[0].name : "untagged";
            if (selectedTag === undefined) {
                navigate(`/builds/${jobSlug}/${defaultTag}`);
            }
        })
        setBuildsStates(prevState => ({...prevState, jobSlug}));

    }, [jobSlug]);

    useEffect(() => {
        setBuildsStates(prevState => ({...prevState, loading: true}));
        refreshList();
    }, [selectedTag]);


    const columns = useMemo(() => [
            {
                Header: '#',
                accessor: 'position',
                Cell: ({row}) => (
                    <div className='row-position clickable'
                         onClick={() => navigate(`/build/${jobSlug}/${selectedTag}/${row.original.id}`)}>
                        <StatusDot status={row.original.status}/>
                        <div className='position'>{row.original.position}</div>
                    </div>)

            },
            {
                Header: 'User',
                accessor: 'user',
                Cell: ({row}) => (
                    <>
                        {row.original.user &&
                            <div className="user">
                                {row.original.user.avatar ? (
                                    <Avatar image={row.original.user.avatar} size="small" />
                                ) : (
                                    <Initials profile={row.original.user}/>
                                )}
                                <span className="username">
                                    {row.original.user.name ? (
                                        `${row.original.user.name}`
                                    ) : (
                                        row.original.user.username
                                    )}
                                </span>
                            </div>
                        }
                    </>
                )

            },
            {
                Header: 'When',
                accessor: 'when',
                Cell: ({row}) => dayjs(row.original.when).fromNow()
            },
            {
                Header: 'Took',
                accessor: 'took',
                Cell: ({row}) => `${formatDuration(row.original.took)} (${formatDuration(row.original.took_avg)} avg)`
            },
            {
                Header: 'Total',
                Cell: ({row}) => (
                    <>
                        <StatusBar statusCounts={row.original.status_counts}/>
                    </>
                )
            },
            {
                Header: 'Options',
                Cell: ({row}) => (
                    <TableActions row={row.original} name='Build' rowName={row.original.position}
                                  onShow={(build) => navigate(`/build/${jobSlug}/${selectedTag}/${build.id}`)}
                                  onDelete={deleteAction}/>

                )
            }
        ]
        , [builds]);

    const sortedBuilds = [...builds].sort((a, b) => dayjs(b.when) - dayjs(a.when));
    return (
        <Card className="builds">
            <CardHeader pathnames={[["builds", "Builds"], [jobSlug, jobName]]} icon={buildIcon}
                        loading={buildsStates.loadingTags}
            >
                {!buildsStates.loadingTags && jobSlug === buildsStates.jobSlug &&
                    <Filter items={tags}
                            onClick={itemName => navigate(`/builds/${jobSlug}/${itemName.toLocaleLowerCase()}`)}
                            initialTab={selectedTag}
                    />
                }
            </CardHeader>
            <CardBody>
                <CardReports lastBuildStatus={sortedBuilds[0]?.status}
                             lastBuildDate={sortedBuilds[0] && dayjs(sortedBuilds[0].when).fromNow()} data={sortedBuilds}/>
                <Table columns={columns} data={sortedBuilds}
                       loading={buildsStates.loading}/>
            </CardBody>
            <Tooltip id="status-tooltip" className='builds-tooltip'/>
        </Card>
    );
}

export default Builds;
