import React from 'react';
import {Navigate} from 'react-router-dom';
import { useAuth } from "../../context/AuthContext/AuthContext";

const ProtectedRoute = ({ component: Component, roles, ...rest }) => {
    const { user, loading } = useAuth();

    if (loading) {
        return null
    }

    if (!user) {
        return <Navigate to="/login" />
    }

    if (roles && !roles.includes(user.role)) {
        return <Navigate to="/" />
    }

    return <Component {...rest} />
};

export default ProtectedRoute;