import React, {useEffect, useState} from 'react';
import * as actions from "./IntegrationsActions";
import JenkinsConnectionBox from "../../common/JenkinsConnectionBox/JenkinsConnectionBox";
import FormField from "../../common/formField/FormField";
import IntegrationModal from "./integrations/IntegrationModal";
import CardBody from "../../containers/card/CardBody";
import Card from "../../containers/card/Card";
import {SettingsCardHeader} from "./SettingsCardHeader";

const Integrations = () => {
    const [integrations, setIntegrations] = useState([]);
    const [selectedIntegration, setSelectedIntegration] = useState(null);

    const loadIntegrationsList = () => {
        actions.loadIntegrations((data) => {
            setIntegrations(data);
        });
    };

    const saveIntegrations = (values) => {
        actions.saveIntegration(values, () => {
            loadIntegrationsList();
        });
    };

    useEffect(() => {
        loadIntegrationsList();
    }, []);

    return <Card className="settings integrations">
        <SettingsCardHeader tab="integrations"/>
        <CardBody className="form">
            <form>
                <FormField
                    id='integrations'
                    className='jenkins'
                    label='Integrations'
                    renderInput={false}
                >
                    {integrations.map((integration) => (
                        <JenkinsConnectionBox
                            key={integration.id}
                            name={integration.name}
                            connectable={false}
                            clickAction={() => setSelectedIntegration(integration)}
                        />
                    ))}
                    <button className="connect" type="button" onClick={() => setSelectedIntegration({})}>
                        <p>+</p>
                        <p>Click to connect</p>
                    </button>
                </FormField>
                {selectedIntegration &&
                    <IntegrationModal
                        onClose={() => setSelectedIntegration(null)}
                        onSave={saveIntegrations}
                        onDelete={selectedIntegration ? (id) => {
                            actions.deleteIntegration(id, loadIntegrationsList);
                        } : null}
                        integration={selectedIntegration}
                    />}
            </form>
        </CardBody>
    </Card>;
};

export default Integrations;
