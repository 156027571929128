export const statusColors = {
    "PASSED": "#02B268",
    'FAILED': '#FC8182',
    "IN_PROGRESS": "#7DB4FC",
    "PENDING": "#d3d3d3",
    "MISMATCH": "#FFEE94FF",
    "CANCELED": "#011145",
};

export const stepResultText = {
    'FAILED': 'Failed',
    'MISMATCH': 'Mismatch',
    'PASSED': 'Passed',
    "IN_PROGRESS": "In Progress"
};
export const statusText = {
    ...stepResultText,
    "PENDING": "Pending",
    "CANCELED": "Canceled",
}
