import axios from "axios";

export const getLdap = (callback) => {
    const config = {
        url: "/ldaps/get_ldap",
        method: "GET"
    }
    axios.request(config).then((response) => callback(response.data))
}

export const connectLdap = (params, callback, onError) => {
    const config = {
        url: '/ldaps/connect_ldap',
        method: "POST",
        params: params
    }

    axios.request(config).then((response) => {
        callback(response.data)
    }).catch(onError)
}

export const syncLdap = (callback) => {
    const config = {
        url: "/ldaps/sync_ldap",
        method: "POST"
    }

    axios.request(config).then((response) => {
        callback()
    })
}

export const disconnectLdap = (callback) => {
    const config = {
        url: "/ldaps/disconnect_ldap",
        method: "POST"
    }

    axios.request(config).then(callback)
}