import axios from "axios";

export const loadJob = (id, callback) => {
    const config = {
        url: `/jobs/${id}`
    }

    axios.request(config).then(
        response => {
            callback(response.data);
        }
    )
}

export const saveJob = (data, callback) => {
    const config = {
        url: data.id ? `/jobs/${data.slug}` : '/jobs',
        method: data.id ? 'PUT' : 'POST',
        data: {
            job: data
        }
    }

    axios.request(config).then(
        response => {
            callback(response.data);
        }
    )
}

export const loadContextData = (callback) => {
    const config = [
        {url: '/groups'},
        {url: '/integrations'}];
    const actions = config.map(c => axios.request(c));
    axios.all(actions).then(all => {
        const result = {
            groups: all[0].data,
            integrations: all[1].data
        };
        callback(result);
    })
}