import React from "react";
import './CardHeader.scss';
import Button from "../../common/buttons/Button";
import {useNavigate} from "react-router-dom";
import CancelSaveButtons from "../../common/buttons/CancelSaveButtons";
import Icon, {breadcrumbArrowIcon} from "../../common/icons/Icon";
import classnames from "classnames";

const CardHeader = ({children, pathnames = [], onAdd, onCancel, onSave, goBack, icon, loading, tabs}) => {

    const navigate = useNavigate();

    const handleNavigate = (path) => {
        navigate(path);
    }

    const formatBreadcrumb = (pathName, index) => {
        const isLast = index === pathnames.length - 1;
        let formattedName = pathName[1];
        let breadcrumbLink = !isLast ? "/" + pathName[0] : null;

        return (
            <div key={pathName + index} onClick={() => breadcrumbLink && handleNavigate(breadcrumbLink)}
                 className={classnames('item',
                     {first: index === 0},
                     {last: isLast},
                     {clickable: breadcrumbLink})}>
                {formattedName}
                {!isLast && <Icon icon={breadcrumbArrowIcon}/>}
            </div>
        );

    };

    return (
        <div className='card-header'>
            <div className='header'>
                <div className='breadcrumb'>
                    <Icon icon={icon}/>
                    <div className="title">
                        {pathnames.map((pathName, index) => formatBreadcrumb(pathName, index))}
                        {loading && <div className='loader'/>}
                    </div>
                </div>
                {tabs}
                {onAdd && <Button color='primary' onClick={onAdd}>+ Add New</Button>}
                {onSave && onCancel &&
                    <div className='buttons'>
                        <CancelSaveButtons onSave={onSave} onCancel={onCancel}/>
                    </div>
                }
                {goBack && <Button color='primary' onClick={goBack}>Back</Button>}
                {children}
            </div>
        </div>
    );
}

export default CardHeader;