import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import * as actions from "./BuildActions";
import "./Build.scss";
import CardHeader from "../../containers/card/CardHeader";
import Button from "../../common/buttons/Button";
import Card from "../../containers/card/Card";
import Loader from "../../common/loader/Loader";
import {buildIcon} from "../../common/icons/Icon";
import CardBody from "../../containers/card/CardBody";
import ReportSection from "../../common/././reportSection/ReportSection";
import PieChart from "../../containers/chart/PieChart";
import dayjs from "dayjs";
import HistoryViewDock from "../../common/docks/HistoryViewDock";
import BuildStatus from "../../containers/card/BuildStatus";
import {formatDuration, formatTagName, getFeatureTotalTime} from "../../common/utils/utils";

const relativeTime = require("dayjs/plugin/relativeTime")
dayjs.extend(relativeTime)

const Build = () => {
    const {id, jobSlug, tag} = useParams();
    const navigate = useNavigate();
    const [build, setBuild] = useState('');
    const [loading, setLoading] = useState(true);
    const [showReportHistory, setShowReportHistory] = useState(null)


    useEffect(() => {
        if (id) {
            setLoading(true)
            actions.loadBuild(jobSlug, id, (data) => {
                setBuild(data);
                setLoading(false);
            });
        }
    }, [id]);

    const goBack = () => {
        navigate(`/builds/${jobSlug}/${tag}`);
    }

    const handleDownload = () => {
        actions.downloadBuildFile(id, build.token);
    }
    const totalScenarios = build.report?.features.reduce((acc, feature) => {
        return acc + feature.scenarios.length;
    }, 0);

    const sum = build.report?.features.map(getFeatureTotalTime)
        .reduce((partialSum, a) => partialSum + a, 0);
    const totalTime = formatDuration(sum);


    const tagName = build.tag_name || "untagged";
    let breadcrumb = [["builds", "Builds"],
        [`builds/${jobSlug}`, build.job_name],
        [`builds/${jobSlug}/${tagName}`, formatTagName(tagName)],
        [null, `#${build.position?.toString()}`]];
    return (
        <>
            <Card className='build'>
                <CardHeader
                    goBack={goBack}
                    pathnames={loading ? [["builds", "Builds"], [null, null]] : breadcrumb}
                    icon={buildIcon}
                    loading={loading}
                    name={`#${build.position}`}
                />
                <CardBody>
                    {loading ? <Loader/> :
                        <div className='content'>
                            <div className="header">
                                <h1>
                                    {totalScenarios}
                                    <p>Scenarios</p>
                                </h1>
                                <PieChart data={build.status_counts}/>
                                <div className='action-group'>
                                    <BuildStatus status={build.status} time={dayjs(build.when).fromNow()} label="Run"/>
                                    <div className='number'>
                                        <span>No: </span>
                                        <span>#{build.position}</span>
                                    </div>
                                    <div className='number'>
                                        <span>Running Time: </span>
                                        <span>{formatDuration(build.took)}</span>
                                    </div>
                                    {build.report && <div className='number'>
                                        <span>Total Time: </span>
                                        <span>{totalTime}</span>
                                    </div>}
                                    <div className='file-button-wrapper'>
                                        <div className='file'>
                                            <span>File: </span>
                                            <span>{build.file_name}</span>
                                            {build.file_name &&
                                                <Button color='primary' onClick={handleDownload}>Download</Button>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {build.report &&
                                <ReportSection report={build.report} onScenarioClick={setShowReportHistory}/>}
                        </div>
                    }
                </CardBody>
            </Card>
            {showReportHistory &&
                <HistoryViewDock build={build} report={showReportHistory}
                                 onCancel={() => setShowReportHistory(false)}/>}
        </>
    );
}

export default Build;
