import React from 'react';
import {Line} from 'react-chartjs-2';
import {CategoryScale, Chart as ChartJS, Filler, Legend, LinearScale, LineElement, Tooltip,} from 'chart.js';
import dayjs from "dayjs";
import {statusColors, statusText, stepResultText} from "../../common/utils/constants";

ChartJS.register(
    CategoryScale,
    LinearScale,
    LineElement,
    Tooltip,
    Legend,
    Filler
);

const StackedLineChart = ({data}) => {
    const sorted = [...data].sort((a, b) => dayjs(a.when) - dayjs(b.when));
    const labels = sorted.map(item => dayjs(item.when).format('DD/MM'));

    const chartData = Object.keys(stepResultText).map(status => ({
        data: sorted.map(s => s.status_counts).map(x => x.filter(s => s.status === status)[0].count),
        label: statusText[status],
        fill: true,
        backgroundColor: statusColors[status],
        borderWidth: 0,
        tension: 0,
        pointRadius: false
    }));

    const dataConfig = {
        labels: labels,
        datasets: chartData
    };

    const options = {
        responsive: true,
        plugins: {
            legend: {
                display: false
            },
            tooltip: {
                mode: 'index',
                intersect: false,
            },
        },
        scales: {
            y: {
                stacked: true,
                min: 0,
                ticks: {
                    stepSize: 1
                }
            },
            x: {
                stacked: false,
            },
        },
    };

    return (
        <div className="section stacked-line">
            <div className="chart-container">
                <div className="label">Test Result Trend for Builds</div>
                <div className="chart">
                    <Line data={dataConfig} options={options}/>
                </div>
            </div>
        </div>
    );
};

export default StackedLineChart;
