import axios from "axios";

export const loadLogs = callback => {
    const config = {
        url: '/http_logs'
    };
    axios.request(config).then(response => {
        callback(response.data)
    })
}

export const deleteClient = (id, callback) => {
    const config = {
        url: `/clients/${id}`,
        method: 'DELETE'
    }

    axios.request(config).then(
        response => {
            callback();
        }
    )
}

