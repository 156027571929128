import Modal from "../../../common/modals/Modal";
import React, {useState} from "react";
import Button from "../../../common/buttons/Button";
import ModalHeader from "../../../common/modals/ModalHeader";
import ModalBody from "../../../common/modals/ModalBody";
import ModalFooter from "../../../common/modals/ModalFooter";

const DisconnectLdapModal = ({onClose, onDisconnect}) => {
    const [loading, setLoading] = useState(false)

    const disconnectLdap = () => {
        setLoading(true)
        onDisconnect(() => setLoading(false))
    }

    return (
        <Modal isOpen={true} height="fit-content" onClose={!loading && onClose}>
            <ModalHeader title="Disconnect" value="LDAP"/>
            <ModalBody>
                Are you sure that you want to disconnect LDAP?
            </ModalBody>
            <ModalFooter>
                <Button color="secondary" onClick={onClose} disabled={loading}>Cancel</Button>
                <Button color="primary" onClick={() => disconnectLdap()} loading={loading}>Disconnect</Button>
            </ModalFooter>
        </Modal>
    )
}

export default DisconnectLdapModal