import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './index.scss';
import {ContextProvider} from "./context/ContextProvider/ContextProvider";
import {AuthProvider} from "./context/AuthContext/AuthContext";
import {store} from "./redux/store";
import {Provider} from "react-redux";
import AxiosSetup from "./axios/AxiosSetup";
import {BrowserRouter as Router} from "react-router-dom";
import {client} from 'scrumship-client';
client.log('J6NWrEI');


ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <AuthProvider>
                <ContextProvider>
                    <Router>
                        <AxiosSetup/>
                        <App/>
                    </Router>
                </ContextProvider>
            </AuthProvider>
        </Provider>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
