import {statusText} from "./constants";
import _, {startCase, toLower} from "lodash";
import dayjs from "dayjs";

export const handleSelect = (formik) => (selectedOption, field) => {
    // TODO: extract it from here
    formik.setFieldValue(field.name, selectedOption);
}

export const formatTagName = (name) => startCase(toLower(name));

export const groupStatuses = (data) => Object.entries(_.groupBy(data, "status"))
    .map(([status, elements]) => ({status, count: elements.length}));

export const settingsTabs = (role) => {
    return role === "admin" ? [
        {name: "Profile"},
        {name: "Groups"},
        {name: "Integrations"},
        {name: "LDAP"},
    ] : [{name: "Profile"}];
}
export const arrayMove = (arr, fromIndex, toIndex) => {
    const element = arr[fromIndex];
    arr.splice(fromIndex, 1);
    arr.splice(toIndex, 0, element);
}
export const requiredMessage = "is required";
export const formatDuration = (durationInSeconds) => {
    const formatTime = (timeInSeconds) => {
        return dayjs.duration(timeInSeconds, 'seconds').format('H[h] m[m] s[s]')
            .replace(/\b0m\b/, '')
            .replace(/\b0s\b/, '')
            .replace(/\b0h\b/, '').trim();
    };

    return formatTime(durationInSeconds || 0) || "0s";
}

export const formikDefaults = {
    validateOnBlur: false,
    validateOnChange: false,
};

export const handleNestedAttributes = (formik, element, field) => {
    if (element.index !== null && element.index !== undefined) {
        formik.setFieldValue(field, formik.values[field].map(
            (t) => t.index === element.index ? element : t
        ));
    } else {
        formik.setFieldValue(field, [...formik.values[field], {
            ...element,
            index: formik.values[field].length
        }]);
    }
}

export const calculateTotalTime = (steps) => {
    return _.sumBy(steps.map(s => ({duration: 0, ...s})), 'duration');
};
export const getFeatureTotalTime = (feature) => {
    return calculateTotalTime(feature.scenarios.flatMap(scenario => scenario.steps));
}

export const formatStatus = (status) => {
    return statusText[status];
}
export const userInitials = (name, surname) => {
    if (!name || !surname) {
        return '';
    }
    return `${name.charAt(0)}${surname.charAt(0)}`;
}

export const reverseColor = (background) => {
    const rgb = parseInt(background.slice(1), 16);
    const r = (rgb >> 16) & 0xff;
    const g = (rgb >> 8) & 0xff;
    const b = (rgb >> 0) & 0xff;
    const luma = 0.2126 * r + 0.7152 * g + 0.0722 * b;
    return luma < 128 ? '#fcfcfc' : '#495057';
}