import React from 'react';
import "./JenkinsConnectionBox.scss";
import classNames from 'classnames';

const JenkinsConnectionBox = ({isConnected, connectable, clickAction, name}) => {
    return (
        <div className="jenkins-connection-box" onClick={clickAction}>
            <div className="header">
                <img
                    className={classNames({unactive: !isConnected && connectable})}
                    src="/jenkins-logo.png"
                    alt="Jenkins Logo"/>
            </div>
            <div className="content">
                <div className="name">{name}</div>
                {connectable && (
                    isConnected ? <div className="active label">Connected</div> :
                        <div className="label">Click to connect</div>
                )}
            </div>
        </div>
    );
}

export default JenkinsConnectionBox;
