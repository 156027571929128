import Modal from "../../../common/modals/Modal";
import Button from "../../../common/buttons/Button";
import React, {useState} from "react";
import ModalHeader from "../../../common/modals/ModalHeader";
import ModalFooter from "../../../common/modals/ModalFooter";
import CancelSaveButtons from "../../../common/buttons/CancelSaveButtons";
import ModalBody from "../../../common/modals/ModalBody";

const SyncLdapModal = ({onClose, onSync}) => {
    const [loading, setLoading] = useState(false)
    const syncLdap = () => {
        setLoading(true)
        onSync(() => {
            setLoading(false)
            onClose()
        })
    }

    return (
        <Modal isOpen={true} onClose={!loading && onClose} height="fit-content" className="sync-ldap-modal">
            <ModalHeader title="Sync" value="LDAP"/>
            <ModalBody>
                Are you sure that you want to sync data with LDAP?
            </ModalBody>
            <ModalFooter>
                <Button color="secondary" onClick={() => onClose()} disabled={loading}>Cancel</Button>
                <Button color="primary" onClick={() => syncLdap()} loading={loading}>Sync</Button>
            </ModalFooter>
        </Modal>
    )
}

export default SyncLdapModal